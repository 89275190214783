import React, { useEffect, useState } from "react";
import Navbar from "../../components/ui/navbar/sidenav.components";
// import Logo from "../../assets/images/Layer1.svg";
import { useNavigate } from "react-router-dom";
import "../dashboard/dashboard.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import restimg from "../../assets/images/torestimg.png";
import storepic from "../../assets/images/storesimg.png";
import eventspic from "../../assets/images/feimg.png";
import agentspic from "../../assets/images/totalagents.png";
// import Cookies from 'js-cookie'
import Barchart from "../../components/ui/charts/bar-chart/bar-chart.components";
import Linechart from "../../components/ui/charts/line-chart/line-chart.components";
import Loader from "../../components/ui/loader/loader.components";
import { Calendar } from "primereact/calendar";
import {
  AgentsWeeklyPerformance,
  PopularCatogeriess,
  CuisinesChart,
  WeeklyApprovedRejected,
  RestaurantsAndUsersAdded,
  DashboardCards,
  ReviewsperDay,
  weeklyapprovedrejectedbydate,
  StoresandEvents,
  WeeklyApprovedRejectedStores,
} from "../../api/dashboard.api";
import { getRefreshedToken, isTokenExpired, logout } from "../../api/auth.api";
import axios from "axios";
import Doughnutchart from "../../components/ui/charts/doughnut-chart/doughnut-chart.components";
import { response } from "express";
const Dashboard = () => {
  const [isAdding, setIsadding] = useState(false);
  const apiUrl = "https://devapi.yeldam.com/api";
  const navigate = useNavigate();
  const [barData, setBarData] = useState<any>({ labels: [], data: [] });
  const [storebarData, setStorebarData] = useState<any>({ labels: [], data: [] });
  const [barData1, setBarData1] = useState<any>({ labels: [], data: [] });
  const [cusinechartData, setCusineChartData] = useState({});
  const [PopularCatogeries, setPopularCatogeries] = useState({});
  const [Restaurants, setRestaurants] = useState({});
  const [Organicstores, setOrganicstores] = useState({});
  const [agents, setAgents] = useState({});
  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
    "--text-color-secondary"
  );
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

  const [cardsdata, setcardsdata] = useState<any>();
  const [sroresevents, setstoresevent] = useState<any>();
  const [fromdate, setfromDate] = useState<any>();
  const [todate, settoDate] = useState<any>();
  const [storefromdate, setstorefromdate] = useState<any>();
  const [storetodate, setstoretodate] = useState<any>();
  const [chartOptions, setChartOptions] = useState({});
  const [storeoption, setstoreoptions] = useState({});
  const [agentsoptions, setagentsoptions] = useState({});
  const [catoptions, setcatoptions] = useState({});
  const [cuisineoptions, setcuisineoptions] = useState({});
  const [restoptions, setrestoptions] = useState({});
  const [organicoptions, setorganicoptions] = useState({});
  const [reviewoptions, setreviewoptions] = useState({});


  const popcatdata = async () => {
    try {
      const PopcatCount = await PopularCatogeriess();
      // console.log("object pop cat dataaaaa", PopcatCount);
      const Popcatdata = {
        labels: PopcatCount.data.data.map((report: any) => report._id),
        datasets: [
          {
            data: PopcatCount.data.data.map((report: any) => report.count),
            backgroundColor: [
              "#62B2FD",
              "#9BDFC4",
              "#FAAFBC",
              "#B1ABF8",
              "#ACDDDE",
              "#F7D8BA",
              "#CEC9DF",
              "#E1E1E1",
              "#C8DCB8",
              "#A1A1D4"
            ],

            borderWidth: 10,
          },
        ],
      };

      const options = {
        cutout: "64%",
        plugins: {
          legend: {
            display: false,
            position: "right",
            labels: {
              color: "#3c3c3c",
              font: {
                size: 12,
              },
              pointStyle: "circle",
    
    
            },
          },
        },
    
        onResize: (chart: any, size: any) => {
          chart.canvas.parentNode.style.width = "300px";
          chart.canvas.parentNode.style.height = "200px";
        },
      };

      setcatoptions(options);
      setPopularCatogeries(Popcatdata);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const cuisinedata = async () => {
    try {
      const CuisineCount = await CuisinesChart();
      // console.log("cusssss", CuisineCount);
      const Cuisinedata = {
        labels: CuisineCount.data.data.data.map((report: any) => report._id),
        datasets: [
          {
            data: CuisineCount.data.data.data.map(
              (report: any) => report.count
            ),
            backgroundColor: [
              "#62B2FD",
              "#9BDFC4",
              "#FAAFBC",
              "#B1ABF8",
              "#ACDDDE",
              "#F7D8BA",
              "#CEC9DF",
              "#E1E1E1",
              "#C8DCB8",
              "#A1A1D4"


            ],

            borderWidth: 10,
          },
        ],
      };

      const options = {
        cutout: "64%",
        plugins: {
          legend: {
            display: false,
            position: "right",
            labels: {
              color: "#3c3c3c",
              font: {
                size: 12,
              },
              pointStyle: "circle",
    
    
            },
          },
        },
    
        onResize: (chart: any, size: any) => {
          chart.canvas.parentNode.style.width = "300px";
          chart.canvas.parentNode.style.height = "200px";
        },
      };

      setcuisineoptions(options)
      setCusineChartData(Cuisinedata);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };
  const restaurantsdata = async () => {
    try {
      const restaurantsCount = await DashboardCards();
      const desiredKeys = ["Pending", "Approved", "Rejected"];
      const resultArray = [
        desiredKeys,
        desiredKeys.map((key) => restaurantsCount.data.data[key].toString()),
      ];
      const Restdata = {
        labels: resultArray[0],
        datasets: [
          {
            data: resultArray[1],
            backgroundColor: [
              "#62B2FD",
              "#9BDFC4",
              "#FAAFBC",

            ],

            borderWidth: 10,
          },

        ],
      };
      const options = {
        cutout: "50%",
        plugins: {
          legend: {
            display: false,
            position: "right",
            labels: {
              color: "#3c3c3c",
              font: {
                size: 12,
              },
              pointStyle: "circle",


            },
          },
        },

        onResize: (chart: any, size: any) => {
          chart.canvas.parentNode.style.width = "200px";
          chart.canvas.parentNode.style.height = "200px";
        },
      };


      setRestaurants(Restdata);
      setChartOptions(options);

    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const organicStoresData = async () => {
    try {
      const storesEventsData = await StoresandEvents();
      const desiredKeys = ["Pending", "Approved", "Denied"];
      const resultArray = [
        desiredKeys,
        desiredKeys.map((key) => storesEventsData.data.data[key].toString()),
      ];
      // console.log("hellloooo", resultArray);
      const organicStoresData = {
        labels: resultArray[0],
        datasets: [
          {
            data: resultArray[1],
            backgroundColor: ["#62B2FD", "#9BDFC4", "#FAAFBC"],
            borderWidth: 10,
          },
        ],
      };
      const options = {
        cutout: "50%",
        plugins: {
          legend: {
            display: false,
            position: "right",
            labels: {
              color: "#3c3c3c",
              font: {
                size: 12,
              },
              pointStyle: "circle",


            },
          },
        },

        onResize: (chart: any, size: any) => {
          chart.canvas.parentNode.style.width = "200px";
          chart.canvas.parentNode.style.height = "200px";
        },
      };

      setstoreoptions(options)
      setOrganicstores(organicStoresData);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const AgentsData = async () => {
    try {
      const restaurantsCount = await DashboardCards();
      const desiredKeys = ["TotalActiveAgents"];
      const resultArray = [
        desiredKeys,
        desiredKeys.map((key) => restaurantsCount.data.data[key].toString()),
      ];
      const Restdata = {
        labels: resultArray[0],
        datasets: [
          {
            data: resultArray[1],
            backgroundColor: [

              "#9BDFC4",
              "#FAAFBC",

            ],

            borderWidth: 10,
          },

        ],
      };

      const options = {
        cutout: "50%",
        plugins: {
          legend: {
            display: false,
            position: "right",
            labels: {
              color: "#3c3c3c",
              font: {
                size: 12,
              },
              pointStyle: "circle",
    
    
            },
          },
        },
    
        onResize: (chart: any, size: any) => {
          chart.canvas.parentNode.style.width = "200px";
          chart.canvas.parentNode.style.height = "200px";
        },
      };

      setagentsoptions(options);
      setAgents(Restdata);

    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }

    }
  }

  const DateSelection = async () => {
    try {
      const datawithDate = await weeklyapprovedrejectedbydate(
        formatDate(fromdate),
        formatDate(todate)
      );
      // console.log("Data with dates:", datawithDate);

      const mergedArray = datawithDate.ApprovedCount.map((approvedData: { _id: any; count: any; }) => {
        const matchingRejectedData = datawithDate.RejectedCount.find((rejectedData: { _id: any; }) => rejectedData._id === approvedData._id);
        return {
          _id: approvedData._id,
          approvedCount: approvedData.count,
          rejectedCount: matchingRejectedData ? matchingRejectedData.count : 0
        };
      });

      // console.log("Merged Array for restaurant:", mergedArray);

      const data = {
        labels: mergedArray.map((entry: any) => entry._id),
        datasets: [
          {
            label: "Approved",
            backgroundColor: "#9BDFC4",
            borderColor: "#9BDFC4",
            data: mergedArray.map((entry: { approvedCount: any; }) => entry.approvedCount),
            barThickness: 8,
          },
          {
            label: "Rejected",
            backgroundColor: "#FAAFBC",
            borderColor: "#FAAFBC",
            data: mergedArray.map((entry: { rejectedCount: any; }) => entry.rejectedCount),
            barThickness: 8,
          },
        ],
      };

      const options = {
        maintainAspectRatio: false,
        aspectRatio: 1,
    
        plugins: {
          legend: {
            display: false, // Hide the legend
            position: "bottom",
            align: "start",
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
    
        onResize: (chart: any, size: any) => {
          chart.canvas.parentNode.style.width = "100%";
          chart.canvas.parentNode.style.height = "320px";
        },
      };
      

      setrestoptions(options)
      setBarData(data);
    } catch (error: any) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 400) {
        logout();
        navigate("/");
      } else {
        // Handle other errors
      }
    }
  };

  const formatDate = (date: any) => {
    if (!date) return "";

    if (typeof date === "string" && date.includes("/")) {
      const [month, day, year] = date.split("/");
      return `${day.padStart(2, "0")}/${month.padStart(2, "0")}/${year}`;
    }

    return new Date(date).toLocaleDateString('en-GB');
  };

  const StoresDateSelection = async () => {
    try {
      const storeswithdate = await WeeklyApprovedRejectedStores(
        formatDate(storefromdate),
        formatDate(storetodate)
      );

      // console.log("stores data with dates", storeswithdate);

      const mergedArray = storeswithdate.ApprovedCount.map((approvedStore: { _id: any; count: any; }) => {
        const matchingRejectedStore = storeswithdate.RejectedCount.find((rejectedStore: { _id: any; }) => rejectedStore._id === approvedStore._id);
        return {
          _id: approvedStore._id,
          approvedCount: approvedStore.count,
          rejectedCount: matchingRejectedStore ? matchingRejectedStore.count : 0
        };
      });

      // console.log("mergedArray for stores", mergedArray);

      const data = {
        labels: mergedArray.map((entry: any) => entry._id),
        datasets: [
          {
            label: "Approved",
            backgroundColor: "#9BDFC4",
            borderColor: "#9BDFC4",
            data: mergedArray.map((entry: { approvedCount: any; }) => entry.approvedCount),
            barThickness: 8,
            barPercentage: 0.7,
            categoryPercentage: 0.8
          },
          {
            label: "Rejected",
            backgroundColor: "#FAAFBC",
            borderColor: "#FAAFBC",
            data: mergedArray.map((entry: { rejectedCount: any; }) => entry.rejectedCount),
            barThickness: 8,
            barPercentage: 0.4,
            categoryPercentage: 0.5
          },
        ],
      };

      
      const options = {
        maintainAspectRatio: false,
        aspectRatio: 1,
    
        plugins: {
          legend: {
            display: false, // Hide the legend
            position: "bottom",
            align: "start",
          },
        },
        scales: {
          x: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
          y: {
            ticks: {
              color: textColorSecondary,
            },
            grid: {
              color: surfaceBorder,
            },
          },
        },
    
        onResize: (chart: any, size: any) => {
          chart.canvas.parentNode.style.width = "100%";
          chart.canvas.parentNode.style.height = "320px";
        },
      };

      setorganicoptions(options);
      setStorebarData(data);
    } catch (error: any) {
      console.error("Error fetching data:", error);
      if (error.response && error.response.status === 400) {
        logout();
        navigate("/");
      } else {
        // Handle other errors
      }
    }
  };

  const userreviewsperday = async () => {
    try {
      const reviewdata = await ReviewsperDay();
      // console.log("weeeek", reviewdata);

      const mergedArray = reviewdata.data.restaurantReviews.map((restaurantReview: { _id: any; count: any; }) => {
        const matchingStoreReview = reviewdata.data.storeReviews.find((storeReview: { _id: any; count: any; }) => storeReview._id === restaurantReview._id);
        return {
          _id: restaurantReview._id,
          restaurantCount: restaurantReview.count,
          storeCount: matchingStoreReview ? matchingStoreReview.count : 0
        };
      });

      // console.log("mergedArray for reviews", mergedArray);


      const restaurantDataset = {
        label: 'Restaurants',
        backgroundColor: "#62B2FD",
        borderColor: "#62B2FD",
        data: mergedArray.map((entry: { restaurantCount: any; }) => entry.restaurantCount),
        barThickness: 8,
        barPercentage: 0.7,
        categoryPercentage: 0.8
      };

      const storeDataset = {
        label: 'Organic Stores',
        backgroundColor: "#B1ABF8",
        borderColor: "#B1ABF8",
        data: mergedArray.map((entry: { storeCount: any; }) => entry.storeCount),
        barThickness: 8,
        barPercentage: 0.7,
        categoryPercentage: 0.8
      };

      const datasets = [restaurantDataset, storeDataset];

      const reviews = {
        labels: mergedArray.map((entry: any) => entry._id),
        datasets: datasets
      };

      
  const options = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        display: false,
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
    onResize: (chart: any, size: any) => {
      chart.canvas.parentNode.style.width = "100%";
      chart.canvas.parentNode.style.height = "180px";
    },
  };

      setBarData1(reviews);
      setreviewoptions(options);

    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const dashbaorddata = async () => {
    try {
      const Dashboard = await DashboardCards();
      // console.log("object of restttt", Dashboard.data.data);
      setcardsdata(Dashboard.data.data);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };
  const Dashboardstoresandevents = async () => {
    try {
      const dashboardstoreevents = await StoresandEvents();
      // console.log("object dashboard stores", dashboardstoreevents.data.data);
      setstoresevent(dashboardstoreevents.data.data)

    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  }

  useEffect(() => {
    setIsadding(true);
    dashbaorddata();
    Dashboardstoresandevents();
    organicStoresData();
    AgentsData();
    popcatdata();
    cuisinedata();
    userreviewsperday();
    restaurantsdata();
    setIsadding(false);
  }, []);

  useEffect(() => {
    DateSelection();
  }, [fromdate, todate]);

  useEffect(() => {
    StoresDateSelection();
  }, [storefromdate, storetodate]);


  return (
    <>
      <main>
        {!isAdding ? "" : <Loader />}

        <div>
          <Navbar />
          <section className="page-content">
            <div className="ym-content-table">
              <div className="container-fluid">

                <div className="pl-2">
                  <h1 className='dashboard-heading'>Dashboard</h1>
                </div>
                <hr style={{ margin: "0px" }} />
                <div className="section1div mb-4 p-2">
                  <div className="col-lg-12 d-flex">
                    <div className="col-lg-2 d-flex flex-column gap-2">
                      <div className="total-rests d-flex align-items-center gap-2 p-2">
                        <div className="total-rest-image-div d-flex align-items-center justify-content-center">
                          <img className="pic-pic" src={restimg} alt="pic" />
                        </div>
                        <div className="totalrest-count">
                          <p className="count-value">{cardsdata?.Total}</p>
                          <p className="count-heading">Total Restaurants</p>
                        </div>

                      </div>
                      <div className="pending-rests d-flex align-items-center justify-content-between p-2">
                        <span className="pend-text">Pending Restaurants</span>
                        <div className="pending-count-div d-flex align-items-center justify-content-center">
                          <span className="pending-count">{cardsdata?.Pending}</span>
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-2 d-flex flex-column gap-2">
                      <div className="total-rests d-flex align-items-center gap-2 p-2">
                        <div className="total-store-image-div d-flex align-items-center justify-content-center">
                          <img className="pic-pic" src={storepic} alt="pic" />
                        </div>
                        <div className="totalrest-count">
                          <p className="count-value">{sroresevents?.Total}</p>
                          <p className="count-heading">Total Organic Stores</p>
                        </div>

                      </div>
                      <div className="pending-rests d-flex align-items-center justify-content-between p-2">
                        <span className="pend-text">Pending Organic Stores</span>
                        <div className="pending-count-div d-flex align-items-center justify-content-center">
                          <span className="pending-count">{sroresevents?.Pending}</span>
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-2 d-flex flex-column gap-2">
                      <div className="total-rests d-flex align-items-center gap-2 p-2">
                        <div className="total-events-image-div d-flex align-items-center justify-content-center">
                          <img className="pic-pic" src={eventspic} alt="pic" />
                        </div>
                        <div className="totalrest-count">
                          <p className="count-value">{sroresevents?.TotalEvents}</p>
                          <p className="count-heading">Total Food Events</p>
                        </div>

                      </div>
                      <div className="pending-rests d-flex align-items-center justify-content-between p-2">
                        <span className="pend-text">Pending Food Events</span>
                        <div className="pending-count-div d-flex align-items-center justify-content-center">
                          <span className="pending-count">{sroresevents?.PendingEvents}</span>
                        </div>
                      </div>

                    </div>
                    <div className="col-lg-2 d-flex flex-column gap-2">
                      <div className="total-rests d-flex align-items-center gap-2 p-2">
                        <div className="total-agents-image-div d-flex align-items-center justify-content-center">
                          <img className="pic-pic" src={agentspic} alt="pic" />
                        </div>
                        <div className="totalrest-count">
                          <p className="count-value">{cardsdata?.TotalAgents}</p>
                          <p className="count-heading">Total Agents</p>
                        </div>

                      </div>
                      <div className="pending-rests d-flex align-items-center justify-content-between p-2">
                        <span className="pend-text">New Agents</span>
                        <div className="newagents-div d-flex align-items-center justify-content-center">
                          <span className="pending-count">{cardsdata?.NewlyRegisteredAgents}</span>
                        </div>
                      </div>

                    </div>

                    <div className="col-lg-4 sidediv p-2">
                      <div className="app-food-events p-3 d-flex justify-content-between">
                        <div className="text-fee d-flex align-items-center  gap-2">
                          <i className="pi pi-check-circle" style={{ fontSize: "16px", color: "#2BC04C" }}></i>
                          <span className="app-fe-text">Approved Food Events</span>
                        </div>
                        <span className="ev-count">{sroresevents?.ApprovedEvents}</span>

                      </div>
                      <div className="app-food-events p-3 d-flex justify-content-between">
                        <div className="text-fee d-flex align-items-center  gap-2">
                          <i className="pi pi-times-circle" style={{ fontSize: "16px", color: " #D83B01" }}></i>
                          <span className="app-fe-text">Rejected Food Events</span>
                        </div>
                        <span className="ev-count">{sroresevents?.DeniedEvents}</span>

                      </div>
                    </div>

                  </div>
                </div>

                <div className="section2div d-flex gap-1  p-2 mb-4 col-lg-12">
                  <div className="rest-chart-div col-lg-4">
                    <span className="chart-head">Restaurants</span>
                    <div className="chart-top-bodies d-flex align-items-center justify-content-around mt-2 p-2">
                      <div className=" d-flex  align-items-center">
                        <div>
                          <Doughnutchart
                            data={Restaurants as any}
                            options={chartOptions as any}
                          />
                        </div>

                      </div>
                      <div className="d-flex flex-column gap-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <i className="pi pi-circle-on mr-2" style={{ color: "#9BDFC4" }}></i>
                          <span className="text-chart-opt mr-3">Approved</span>
                          <span className="text-chart-opt">{cardsdata?.Approved}</span>
                        </div>
                        <div className="d-flex  align-items-center">
                          <i className="pi pi-circle-on mr-2" style={{ color: "#FAAFBC" }}></i>
                          <span className="text-chart-opt mr-3">Rejected</span>
                          <span className="text-chart-opt">{cardsdata?.Rejected}</span>
                        </div>
                        <div className="d-flex  align-items-center">
                          <i className="pi pi-circle-on mr-2" style={{ color: "#62B2FD" }}></i>
                          <span className="text-chart-opt mr-3">Pending</span>
                          <span className="text-chart-opt">{cardsdata?.Pending}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="stores-chart-div col-lg-4">
                    <span className="chart-head">Organic Stores</span>
                    <div className="chart-top-bodies d-flex align-items-center justify-content-around mt-2">
                      <div className=" d-flex justify-content-around align-items-center">
                        <div>
                          <Doughnutchart
                            data={Organicstores as any}
                            options={storeoption as any}
                          />
                        </div>

                      </div>
                      <div className="d-flex flex-column gap-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <i className="pi pi-circle-on mr-2" style={{ color: "#9BDFC4" }}></i>
                          <span className="text-chart-opt mr-3">Approved</span>
                          <span className="text-chart-opt">{sroresevents?.Approved}</span>
                        </div>
                        <div className="d-flex  align-items-center">
                          <i className="pi pi-circle-on mr-2" style={{ color: "#FAAFBC" }}></i>
                          <span className="text-chart-opt mr-3">Rejected</span>
                          <span className="text-chart-opt">{sroresevents?.Denied}</span>
                        </div>
                        <div className="d-flex  align-items-center">
                          <i className="pi pi-circle-on mr-2" style={{ color: "#62B2FD" }}></i>
                          <span className="text-chart-opt mr-3">Pending</span>
                          <span className="text-chart-opt">{sroresevents?.Pending}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4 agents-chart-div">
                    <span className="chart-head">Agents</span>
                    <div className="chart-top-bodies d-flex align-items-center justify-content-around mt-2">
                      <div className=" d-flex justify-content-around align-items-center">
                        <div>
                          <Doughnutchart
                            data={agents as any}
                            options={agentsoptions as any}
                          />
                        </div>

                      </div>
                      <div className="d-flex flex-column gap-3">
                        <div className="d-flex justify-content-between align-items-center">
                          <i className="pi pi-circle-on mr-2" style={{ color: "#9BDFC4" }}></i>
                          <span className="text-chart-opt mr-3">Active</span>
                          <span className="text-chart-opt">{cardsdata?.TotalActiveAgents}</span>
                        </div>
                        <div className="d-flex  align-items-center">
                          <i className="pi pi-circle-on mr-2" style={{ color: "#FAAFBC" }}></i>
                          <span className="text-chart-opt mr-3">Inactive</span>
                          <span className="text-chart-opt">0</span>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>

              </div>

              {/* second */}

              <div className="d-flex align-items-center justify-content-between mb-4 pl-3 pr-3">
                <div className="weekgraph p-2">
                  <div className="week-head" >{" "}Weekly Status of Restaurants</div>

                  <div className="mt-2">

                    <div className="row">
                      <div className="col-12">
                        <Barchart data={barData} options={restoptions as any} />
                      </div>
                    </div>
                    <div className="d-flex gap-4 justify-content-end">
                      <div className="d-flex align-items-center calen" style={{ width: "125px", height: "32px" }}>

                        <Calendar
                          value={fromdate}
                          onChange={(e) => setfromDate(e.target.value)}
                          showIcon
                          placeholder="From Date"
                        // dateFormat="dd/mm/yy"
                        />
                      </div>
                      <div className="d-flex align-items-center calen" style={{ width: "125px", height: "32px" }}>

                        <Calendar
                          value={todate}
                          onChange={(e) => settoDate(e.target.value)}
                          showIcon
                          placeholder="To Date"
                        // dateFormat="dd/mm/yy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="weekgraph p-2">
                  <div className="week-head" >{" "}Weekly Status of Organic Stores</div>

                  <div className="mt-2">

                    <div className="row">
                      <div className="col-12">
                        <Barchart data={storebarData} options={organicoptions as any} />
                      </div>
                    </div>
                    <div className="d-flex gap-4 justify-content-end">
                      <div className="d-flex align-items-center calen" style={{ width: "125px", height: "32px" }}>

                        <Calendar
                          value={storefromdate}
                          onChange={(e) => setstorefromdate(e.target.value)}
                          showIcon
                          placeholder="From Date"
                          
                        // dateFormat="dd/mm/yy"
                        />
                      </div>
                      <div className="d-flex align-items-center calen" style={{ width: "125px", height: "32px" }}>

                        <Calendar
                          value={storetodate}
                          className=""
                          onChange={(e) => setstoretodate(e.target.value)}
                          showIcon
                          placeholder="To Date"
                        // dateFormat="dd/mm/yy"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>


              <div className="row pl-3 pr-3 justify-content-around">
                <div className="col-xl-3 col-lg-3 col-md-3 mb-2 p-3">
                  <div
                    className="week-head mb-2"

                  >
                    {" "}
                    Popular Catogeries
                  </div>
                  

                  <div className="chart-body d-flex justify-content-center align-items-center">
                    <div className="row justify-content-center align-items-center">
                      <Doughnutchart
                        data={PopularCatogeries}
                        options={catoptions}
                      />
                    </div>
                  </div>

                </div>
                <div className="col-xl-3 col-lg-3 col-md-3 mb-2 p-3">
                  <div
                    className="week-head mb-2"

                  >
                    {" "}
                    Cuisines
                  </div>
                
                  <div className="chart-body d-flex justify-content-center align-items-center">

                    <div className="row justify-content-center align-items-center ">
                      <Doughnutchart
                        data={cusinechartData}
                        options={cuisineoptions}
                      />
                    </div>

                  </div>
                </div>
                <div className="col-xl-6 col-lg-6 col-md-6 mb-4 mb-2 p-3">
                  <div
                    className="week-head mb-2"

                  >
                    {" "}
                    Reviews
                  </div>
                  
                  <div className="chart-body d-flex flex-column">
                    <div className=" mt-2 mb-2">
                      {/* <div className="flex align-items-center justify-content-between mb-3">
                        <h5 className="mb-0 fw-600">Categories</h5>
                      </div> */}
                      <Barchart data={barData1} options={reviewoptions as any} />
                    </div>
                    <div className="d-flex justify-content-center  gap-3">
                      <div className="d-flex justify-content-between align-items-center">
                        <i className="pi pi-circle-on mr-2" style={{ color: "#62B2FD" }}></i>
                        <span className="text-chart-opt mr-3">Restaurants</span>

                      </div>
                      <div className="d-flex  align-items-center">
                        <i className="pi pi-circle-on mr-2" style={{ color: "#B1ABF8" }}></i>
                        <span className="text-chart-opt mr-3">Organic Stores</span>

                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Dashboard;
