import React, { useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { AddData } from "../../../api/settings.api";
import "./addPopularcategories.components.css";
type props = {
  show: boolean;
  setShow: Function;
};
const AddPopularcategoryModal: React.FC<props> = ({ show, setShow }) => {
  const schema = Yup.object().shape({
    type: Yup.string().required("Type is a required field"),
    name: Yup.string().required("name is required"),
    imageurl: Yup.string().required("image is required"),
  });

  const AddFooter = (
    <div>
      <Button
        label="Save"
        form="add"
        type="submit"
        className="p-button-rounded p-button-success save-btn-login"
      ></Button>
    </div>
  );
  const addPopularcategories = async (values: any) => {
    const respData = await AddData(values);
    // toastShow(respData.data.message);
    setShow(false);
    refreshPage();
  };
  const refreshPage = () => {
    window.location.reload();
  };
  return (
    <>
      {/* <Toast ref={toast} /> */}
      <Dialog
        header="Add PopularCategory"
        visible={show}
        style={{ width: "30vw" }}
        onHide={() => setShow(false)}
        footer={AddFooter}
      >
        <Formik
          validationSchema={schema}
          initialValues={{ type: "", name: "", imageurl: "" }}
          onSubmit={(values) => {
            addPopularcategories(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <div className="justify-content-center">
              <br></br>
              {/* <p className="logo_sub_text">
                        <span style={{ fontWeight: "bold" }}>{heading}</span>
                      </p> */}
              <form id="add" noValidate onSubmit={handleSubmit}>
                <div className="flex flex-column">
                  <label htmlFor="type">Type</label>
                  <InputText
                    id="type"
                    type="type"
                    placeholder="Enter Type..."
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                  />
                  <p className="error">
                    {errors.type && touched.type && errors.type}
                  </p>
                </div>

                <div className="flex flex-column">
                  <label htmlFor="name">Name</label>
                  <InputText
                    id="name"
                    type="name"
                    placeholder="Enter Name..."
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  <p className="error">
                    {errors.name && touched.name && errors.name}
                  </p>
                </div>
                <div className="flex flex-column">
                  <label htmlFor="images">Images</label>
                  <InputText
                    id="images"
                    placeholder="Enter URL..."
                    name="imageurl"
                    value={values.imageurl}
                    onChange={handleChange}
                  />
                  <p className="error">
                    {errors.imageurl && touched.imageurl && errors.imageurl}
                  </p>
                </div>
              </form>
            </div>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddPopularcategoryModal;
