import React, { useEffect, useState } from "react";
import Navbar from "../../components/ui/navbar/sidenav.components";
import "../statistics/statistics.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Loader from "../../components/ui/loader/loader.components";
import { Chart } from "primereact/chart";
import {
  tabledata,
  toppagedata,
  topvisitorsData,
  mapvisitorsData,
} from "../statistics/statisticsdata";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import Mapview from "../../components/ui/map/Mapview.component";
import {
  HitStatistics,
  TopBrowsers,
  TopPlatforms,
  TopDevices,
  TopCountries,
  GetVisitorStats,
  TopPages,
  TopVisitors,
  GetMostSearched,
} from "../../api/userstatistics.api";
import { logout } from "../../api/auth.api";
import { useNavigate } from "react-router-dom";
interface User {
  id: string;
  time: string;
  visitors: number;
  visits: number;
}
interface PageData {
  id: number;
  Title: string;
  link: string;
  Visits: number;
}
interface ColumnMeta {
  field: string;
  header: string;
}

const Statistics = () => {
  const navigate = useNavigate();

  const [isAdding, setIsadding] = useState(false); // Loader state..

  const [hitchartData, setHitChartData] = useState({});
  const [hitchartOptions, setHitChartOptions] = useState({});
  const [browserData, setBrowserData] = useState({});
  const [browserOptions, setBrowserOptions] = useState({});
  const [platformsData, setPlatformsData] = useState({});
  const [platformsOptions, setPlatformsOptions] = useState({});
  const [devicesData, setDevicesData] = useState({});
  const [devicesOptions, setDevicesOptions] = useState({});
  const [topcountriesData, setTopcountriesData] = useState({});
  const [getVisitorsData, setGetVisitorsData] = useState({});
  const [getMostSearchedData, setGetMostSearchedData] = useState({});
  const [topPages, setToppages] = useState({});
  const [topVistors, setTopvisitors] = useState({});
  const [visitorsLocationData, setVisitorsLocationData] = useState({});
  const apiUrl = "https://devapi.yeldam.com/api";
  const [userstable, setUserstable] = useState<User[]>([]);
  const columns: ColumnMeta[] = [
    { field: "date", header: "Time" },
    { field: "visitorCount", header: "Visitors" },
    { field: "visitCount", header: "Visits" },
  ];
  const [toppagetable, setTopPagetable] = useState<PageData[]>([]);
  const toppagecolumns: ColumnMeta[] = [
    { field: "id", header: "" },
    { field: "visit_page", header: "Page Name" },
    { field: "pagelink", header: "Page Link" },
    { field: "visitCount", header: "Visits" },
  ];

  const documentStyle = getComputedStyle(document.documentElement);
  const textColor = documentStyle.getPropertyValue("--text-color");
  const textColorSecondary = documentStyle.getPropertyValue(
    "--text-color-secondary"
  );
  const surfaceBorder = documentStyle.getPropertyValue("--surface-border");

  const hitdata = async () => {
    try {
      const Hitstatistics = await HitStatistics();
      const Hitstatisticsdata = {
        labels: Hitstatistics.data.data.Visitors.map(
          (report: any) => report.date
        ),
        datasets: [
          {
            label: "Visitors",
            data: Hitstatistics.data.data.Visitors.map(
              (report: any) => report.visitorCount
            ),
            fill: false,
            borderColor: documentStyle.getPropertyValue("--blue-500"),
            tension: 0.4,
          },
          {
            label: "Visits",
            data: Hitstatistics.data.data.Visits.map(
              (report: any) => report.visits
            ),
            fill: false,
            borderColor: documentStyle.getPropertyValue("--pink-500"),
            tension: 0.4,
          },
        ],
      };
      setHitChartData(Hitstatisticsdata);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };
  const hitoptions = {
    maintainAspectRatio: false,
    aspectRatio: 0.6,
    plugins: {
      legend: {
        labels: {
          color: textColor,
        },
      },
    },
    scales: {
      x: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
      y: {
        ticks: {
          color: textColorSecondary,
        },
        grid: {
          color: surfaceBorder,
        },
      },
    },
    onResize: (chart: any, size: any) => {
      chart.canvas.parentNode.style.width = "100%";
      chart.canvas.parentNode.style.height = "300px";
    },
  };

  const browserdata = async () => {
    try {
      const BrowsersCount = await TopBrowsers();
      const Browsersdata = {
        labels: BrowsersCount.data.data.map((report: any) => report._id),
        datasets: [
          {
            data: BrowsersCount.data.data.map((report: any) => report.count),
            backgroundColor: [
              "#42A5F5",
              "#66BB6A",
              "#FFA726",
              "#EF5350",
              "#9E9E9E",
            ],
            hoverBackgroundColor: [
              "#64B5F6",
              "#81C784",
              "#FFB74D",
              "#E57373",
              "#BDBDBD",
            ],
          },
        ],
      };
      setBrowserData(Browsersdata);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };
  const browseroptions = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          fontColor: "#495057",
        },
      },
    },
  };

  const platformsoptions = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          fontColor: "#495057",
        },
      },
    },
  };

  const platformsdata = async () => {
    try {
      const PlatformsCount = await TopPlatforms();
      const Platformsdata = {
        labels: PlatformsCount.data.data.map((report: any) => report._id),
        datasets: [
          {
            data: PlatformsCount.data.data.map((report: any) => report.count),
            backgroundColor: [
              "#42A5F5",
              "#66BB6A",
              "#FFA726",
              "#EF5350",
              "#FF7043",
              "#9E9E9E",
            ],
            hoverBackgroundColor: [
              "#64B5F6",
              "#81C784",
              "#FFB74D",
              "#E57373",
              "#FF8A65",
              "#BDBDBD",
            ],
          },
        ],
      };
      setPlatformsData(Platformsdata);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };
  const devicesoptions = {
    plugins: {
      legend: {
        labels: {
          usePointStyle: true,
          fontColor: "#495057",
        },
      },
    },
  };

  const devicesdata = async () => {
    try {
      const DevicesCount = await TopDevices();
      const Devicesdata = {
        labels: DevicesCount.data.data.map((report: any) => report._id),
        datasets: [
          {
            data: DevicesCount.data.data.map((report: any) => report.count),
            backgroundColor: ["#42A5F5", "#66BB6A", "#FFA726", "#EF5350"],
            hoverBackgroundColor: ["#64B5F6", "#81C784", "#FFB74D", "#E57373"],
          },
        ],
      };
      setDevicesData(Devicesdata);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const topcountriesdata = async () => {
    try {
      const Topcountries = await TopCountries();
      setTopcountriesData(Topcountries.data.data);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const GetVisitorsData = async () => {
    try {
      const getVisitors = await GetVisitorStats();
      setGetVisitorsData(getVisitors.data.data);
      console.log(getVisitors.data.data);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const GetMostSearchedData = async () => {
    try {
      const getMostSearched = await GetMostSearched();
      setGetMostSearchedData(getMostSearched.data.data);
      console.log(getMostSearched.data.data);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  const toppagesdata = async () => {
    try {
      const Toppages = await TopPages();
      setToppages(Toppages.data.data);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };
  const topvisitorsdata = async () => {
    try {
      const Topvisitors = await TopVisitors();
      setTopvisitors(Topvisitors.data.data);
    } catch (err: any) {
      if (err.code === "ERR_BAD_REQUEST") {
        logout();
        navigate("/");
      }
    }
  };

  useEffect(() => {
    let timer: NodeJS.Timeout;

    const delayedFetch = () => {
      setIsadding(true);
      // Add a delay of 500ms before making the API call
      timer = setTimeout(() => {
        hitdata();
        setHitChartOptions(hitoptions);
        setUserstable(tabledata);
        browserdata();
        setBrowserOptions(browseroptions);
        platformsdata();
        setPlatformsOptions(platformsoptions);
        setTopPagetable(toppagedata);
        devicesdata();
        setDevicesOptions(devicesoptions);
        topcountriesdata();
        GetVisitorsData();
        GetMostSearchedData();
        toppagesdata();
        topvisitorsdata();
        setIsadding(false);
      }, 500);
    };
    delayedFetch();
    return () => clearTimeout(timer);
  }, []);

  console.log("hi", getVisitorsData);

  return (
    <>
      <main>
        {!isAdding ? "" : <Loader />}

        <div>
          <Navbar />
          <section className="page-content">
            <div className="ym-content-table">
              <div className="container-fluid">
                <div className="row">
                  <div className="col-12">
                    <div className="page_title d-sm-flex">
                      <div className="d-flex align-items-center FE-heading-nav">
                        <a href="/dashboard" className="d-flex">
                          <span className="home-span">Dashboard</span>
                        </a>
                        <i
                          className="pi pi-angle-right"
                          style={{
                            color: "rgb(194, 194, 194)",
                            fontSize: "12px",
                          }}
                        ></i>
                        <span className="event-span">Analytics</span>
                      </div>
                    </div>
                    <hr />
                  </div>
                </div>
           
                    <div className="grid gap-4 mt-2 d-flex justify-content-center align-items-center">
                      <div className="card m-0 p-0 ym-stat-card people-site d-flex justify-content-center align-items-center">
                        <div className="row pl-2">
                          <span className="block stat-text-400 fs-35 mb-1" style={{color:"#44B273"}}>12</span>
                          <span className="block stat-text-400 fs-14 mb-0" style={{color:"#44B273"}}>
                            People on site
                          </span>
                        </div>
                      </div>
                      <div className="card m-0 p-0 ym-stat-card visitors-card">
                          <div className="row m-0 p-0">
                            <span className="d-flex justify-content-between align-items-center">
                            <span className="text-900 fs-35 mb-0">
                              120
                            </span>
                              <i
                                className="pi pi-arrow-up fs-12 p-1"
                                style={{ color: "#107C10" }}
                              ></i>
                            </span>
                          </div>
                          <div className="row p-0 m-0">
                            <span className="d-flex justify-content-between align-items-center">
                            <span className="text-900 fs-14 mb-0">
                             Visitors
                            </span>
                            <span className="fw-400 fs-14" style={{color:"#137246"}}> 10% </span>
                            </span>
                          </div>
                      </div>
                      <div className="card m-0 p-0 ym-stat-card visits-card">
                          <div className="row m-0 p-0">
                            <span className="d-flex justify-content-between align-items-center">
                            <span className="text-900 fs-35 mb-0">
                              120
                            </span>
                              <i
                                className="pi pi-arrow-up fs-12 p-1"
                                style={{ color: "#107C10" }}
                              ></i>
                            </span>
                          </div>
                          <div className="row p-0 m-0">
                            <span className="d-flex justify-content-between align-items-center">
                            <span className="text-900 fs-14 mb-0">
                             Visitors
                            </span>
                            <span className="fw-400 fs-14" style={{color:"#137246"}}> 10% </span>
                            </span>
                          </div>
                      </div>
                      <div className="card m-0 p-0 ym-stat-card avg-on-site">
                          <div className="row m-0 p-0">
                            <span className="d-flex justify-content-between align-items-center">
                            <span className="text-900 fs-35 mb-0">
                              120
                            </span>
                              <i
                                className="pi pi-arrow-up fs-12 p-1"
                                style={{ color: "#107C10" }}
                              ></i>
                            </span>
                          </div>
                          <div className="row p-0 m-0">
                            <span className="d-flex justify-content-between align-items-center">
                            <span className="text-900 fs-14 mb-0">
                             Visitors
                            </span>
                            <span className="fw-400 fs-14" style={{color:"#137246"}}> 10% </span>
                            </span>
                          </div>
                      </div>
                      <div className="card m-0 p-0 ym-stat-card new-signup">
                          <div className="row m-0 p-0">
                            <span className="d-flex justify-content-between align-items-center">
                            <span className="text-900 fs-35 mb-0">
                              120
                            </span>
                              <i
                                className="pi pi-arrow-up fs-12 p-1"
                                style={{ color: "#107C10" }}
                              ></i>
                            </span>
                          </div>
                          <div className="row p-0 m-0">
                            <span className="d-flex justify-content-between align-items-center">
                            <span className="text-900 fs-14 mb-0">
                             Visitors
                            </span>
                            <span className="fw-400 fs-14" style={{color:"#137246"}}> 10% </span>
                            </span>
                          </div>
                      </div>
                      <div className="card m-0 p-0 ym-stat-card response-time">
                          <div className="row m-0 p-0">
                            <span className="d-flex justify-content-between align-items-center">
                            <span className="text-900 fs-35 mb-0">
                              120
                            </span>
                              <i
                                className="pi pi-arrow-up fs-12 p-1"
                                style={{ color: "#107C10" }}
                              ></i>
                            </span>
                          </div>
                          <div className="row p-0 m-0">
                            <span className="d-flex justify-content-between align-items-center">
                            <span className="text-900 fs-14 mb-0">
                             Visitors
                            </span>
                            <span className="fw-400 fs-14" style={{color:"#137246"}}> 10% </span>
                            </span>
                          </div>
                      </div>
                    </div>

                <div className="row mt-2 row-deck">
                  <div className="col-xl-3 col-lg-6 col-md-6"></div>
                </div>
              </div>

              <br></br>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Statistics;
