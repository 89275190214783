import axios from "axios";
import HttpInstance from "./http.api";
import { apiUrl } from '../config/constants';
import { AnyObjectSchema } from "yup";

export const HitStatistics=()=>{
    const RespData =  axios.get(apiUrl+"/uservisit/hitstatistics", 
    { headers: {
        'Accept': '*/*',
       'Authorization': "Bearer "+localStorage.getItem('accessToken')
      } }
      );
      return RespData
}

export const TopBrowsers=()=>{
    const RespData =  axios.get(apiUrl+"/uservisit/browserscount", 
    { headers: {
        'Accept': '*/*',
       'Authorization': "Bearer "+localStorage.getItem('accessToken')
      } }
      );
      return RespData
}

export const TopPlatforms=()=>{
    const RespData =  axios.get(apiUrl+"/uservisit/platformcount", 
    { headers: {
        'Accept': '*/*',
       'Authorization': "Bearer "+localStorage.getItem('accessToken')
      } }
      );
      return RespData
}

export const TopDevices=()=>{
    const RespData =  axios.get(apiUrl+"/uservisit/devicescount",
     { headers: {
        'Accept': '*/*',
       'Authorization': "Bearer "+localStorage.getItem('accessToken')
      } }
      );
      return RespData
}

export const TopCountries=()=>{
  const RespData =  axios.get(apiUrl+"/uservisit/topvisitorcountries",
   { headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken')
    } }
    );
    return RespData
}

export const TopPages=()=>{
  const RespData =  axios.get(apiUrl+"/uservisit/topvisitedpages",
   { headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken')
    } }
    );
    return RespData
}

export const TopVisitors=()=>{
  const RespData =  axios.get(apiUrl+"/uservisit/visitordetails",
   { headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken')
    } }
    );
    return RespData
}

export const GetVisitorStats = ()=>{
    const RespData = axios.get(apiUrl+"/uservisit/getvisitorstats", 
    { headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken')
    } }
    );
    return RespData
}

export const GetMostSearched =()=>{
    const RespData =axios.get(apiUrl+"/uservisit/mostlysearchedwords",
    { headers: {
        'Accept': '*/*',
       'Authorization': "Bearer "+localStorage.getItem('accessToken')
      } }
      );
      return RespData
}

export const TopReferrers =()=>{
    const RespData =axios.get(apiUrl+"/uservisit/toprefferers",
    { headers: {
        'Accept': '*/*',
       'Authorization': "Bearer "+localStorage.getItem('accessToken')
      } }
      );
      return RespData
}
export const TopVisitorCountries=()=>{
    const RespData =  axios.get(apiUrl+"/uservisit/topvisitorcountries",
     { headers: {
        'Accept': '*/*',
       'Authorization': "Bearer "+localStorage.getItem('accessToken')
      } }
      );
      return RespData
  }
  export const GetCardsData=()=>{
    const RespData =  axios.get(apiUrl+"/uservisit/statisticscount",
     { headers: {
        'Accept': '*/*',
       'Authorization': "Bearer "+localStorage.getItem('accessToken')
      } }
      );
      return RespData
  }

  export const CardsDataGraph=(type:string)=>{
    const RespData =  axios.get(apiUrl+"/uservisit/getstatistics?type="+type,
     { headers: {
        'Accept': '*/*',
       'Authorization': "Bearer "+localStorage.getItem('accessToken')
      } }
      );
      return RespData
  }