import React from 'react';
import { Image } from "primereact/image";
import { devsas } from "../../../config/constants"

interface props {
    degree: string;
    collage: string;
    yearofcompletion: string;
    certification: string;
    membership: string[];
    awardsandrecognitions: string[];
    certificationimages: string[];
}
const EducationalInfo: React.FC<props> = ({ degree, collage, yearofcompletion, certification, membership, awardsandrecognitions, certificationimages }) => {
    return (
        <>
            <div>
                <div className='d-flex'>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Degree</h6>
                        <p className='sub-detail-data'>{degree}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">College</h6>
                        <p className='sub-detail-data'>{collage}</p>
                    </div>
                    <div className='col-3'>
                        <h6 className="mb-1 sub-detail-head">Year of Completion</h6>
                        <p className='sub-detail-data'>{yearofcompletion}</p>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Certification</h6>
                        <p className='sub-detail-data'>{certification}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Memberships</h6>
                        <p className='sub-detail-data'>{membership.join(",")}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Awards & Recognitions</h6>
                        <p className='sub-detail-data'>{awardsandrecognitions.join(", ")}</p>
                    </div>
                </div>

                    <div className="col-5">
                        <h6 className="mb-2 sub-detail-head">Medical Registration Proof</h6>
                        <Image className='medical-proof-image' src={certificationimages[0] + "?" + devsas} alt="Image" />
                    </div>
                
            </div>
        </>
    );
}

export default EducationalInfo;
