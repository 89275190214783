import React, { useState } from 'react';
import "./Healthtips.css";
import Navbar from '../../components/ui/navbar/sidenav.components';
import { Button } from 'primereact/button';
import HealthtipsTable from '../../components/HealthTips Table/Healthtips-Table.component';
import { yeldamwebimagessas } from '../../config/constants';
import picture from "../../assets/images/img2.png"
import { Dialog } from "primereact/dialog";
import Addhealthtip from '../../components/HealthTips/Addhealthtip.component';
import Edithealthtip from '../../components/HealthTips/Edithealthtip.component';
import Viewhealthtip from '../../components/HealthTips/Viewhealthtip.component';
import { DeleteHealthtips } from '../../api/healthtips.api';

const Healthtips = () => {

    const [addShow, setAddShow] = useState(false);
    const [editvisible, seteditvisible] = useState(false);
    const [viewvisible, setviewvisible] = useState(false);
    const [deletevisible, setdeletevisible] = useState(false);
    const[id,setId]=useState<any>();


    const addProduct = () => {
        setAddShow(true)
    }
    const editProduct = () => {
        seteditvisible(true)
    };
  


    const deleteProduct = async (tipid:string) => {
            try {
                await DeleteHealthtips(tipid);
                setdeletevisible(false);
            } catch (error) {
                console.error("Error deleting health tip:", error);
            }
        }
    

    const actionBodyTemplate = (rowData:any) => {
        return (
            <React.Fragment>

                <Button icon="pi pi-eye"
                    className="p-dt-tooltip p-button-rounded mr-2 action-btn-icon"
                       onClick={(e) =>{
                        setId(rowData);
                        setviewvisible(true);
                      
                       }
                    }
                  //  onClick={() => viewProduct()}
                />
                <Button icon="pi pi-pencil"
                    className="p-dt-tooltip p-button-rounded mr-2 action-btn-icon "
                    //   onClick={() => editProduct(rowData)}
                    onClick={(e) =>{
                        setId(rowData);
                        seteditvisible(true)
                      
                       }
                    }
               
                />
                <Button icon="pi pi-trash"
                    className="p-dt-tooltip p-button-rounded action-btn-icon"
                    //   onClick={() => deleteProduct(rowData)} 
                    onClick={() => {setdeletevisible(true);setId(rowData?._id)}}
                />
            </React.Fragment>
        );
    }
    const imageBodyTemplate = (rowData: any) => {
        // return <img src={rowData.imageurl+"?"+yeldamwebimagessas} alt={rowData.imageurl} className="w-6rem shadow-2 border-round" />;
        return <img src={picture} className="tip-pic shadow-2 border-round" />


    };

    return (
        <>
            <main>
                <div>
                    <Navbar />
                    <section className="page-content">
                        <div className="ym-content-table">
                            <div className="d-flex">
                                <div className="p-2">
                                    <span className='event-span-head'>Health Tips</span></div>
                                <div className="ml-auto ">
                                    <Button className="p-button-rounded p-button-success add-healthtip-button"
                                        icon="pi pi-plus-circle"
                                        label='Add Health Tip'
                                        onClick={() => addProduct()}
                                    ></Button>
                                </div>
                            </div>
                            <hr className="mb-0"/>
                            <div className="card pt-3 pb-3 pl-0 pr-0 tiptable">
                                <HealthtipsTable
                                    actionBodyTemplate={actionBodyTemplate}


                                />
                            </div>

                        </div>
                    </section>
                </div>
            </main>
            <Dialog
                visible={addShow}
                header="Add Health Tip"
                style={{ width: "25vw" }}
                className="login-model"
                breakpoints={{
                    "1920px": "50vw",
                    "1420px": "50vw",
                    "1120px": "50vw",
                    "1090px": "40vw",
                    "980px": "50vw",
                    "840px": "60vw",
                    "740px": "50vw",
                    "641px": "70vw",
                    "541px": "70vw",
                    "490px": "80vw",
                    "350px": "90vw",
                }}
                onHide={() => setAddShow(false)}
            >
                <Addhealthtip 
                setAddShow={setAddShow}
                />
            </Dialog>
            <Dialog
                visible={editvisible}
                header="Edit Health Tip"
                style={{ width: "25vw" }}
                className="login-model"
                breakpoints={{
                    "1920px": "50vw",
                    "1420px": "50vw",
                    "1120px": "50vw",
                    "1090px": "40vw",
                    "980px": "50vw",
                    "840px": "60vw",
                    "740px": "50vw",
                    "641px": "70vw",
                    "541px": "70vw",
                    "490px": "80vw",
                    "350px": "90vw",
                }}
                onHide={() => seteditvisible(false)}
            >
                <Edithealthtip
                    rowData={id}
                    seteditvisible={seteditvisible}                />
            </Dialog>
            <Dialog
                visible={viewvisible}
                header="Health Tip"
                style={{ width: "25vw" }}
                className="login-model"
                breakpoints={{
                    "1920px": "50vw",
                    "1420px": "50vw",
                    "1120px": "50vw",
                    "1090px": "40vw",
                    "980px": "50vw",
                    "840px": "60vw",
                    "740px": "50vw",
                    "641px": "70vw",
                    "541px": "70vw",
                    "490px": "80vw",
                    "350px": "90vw",
                }}
                onHide={() => setviewvisible(false)}
            >
                <Viewhealthtip 
                rowData={id}
                setdeletevisible={setdeletevisible}
                seteditvisible={seteditvisible}

                />
            </Dialog>
            
            <Dialog
                visible={deletevisible}
                // header="delete"
                style={{ width: "25vw" }}
                className="delete-dialog"
                breakpoints={{
                    "1920px": "50vw",
                    "1420px": "50vw",
                    "1120px": "50vw",
                    "1090px": "40vw",
                    "980px": "50vw",
                    "840px": "60vw",
                    "740px": "50vw",
                    "641px": "70vw",
                    "541px": "70vw",
                    "490px": "80vw",
                    "350px": "90vw",
                }}
                onHide={() => setdeletevisible(false)}
            >
                <div className='d-flex justify-content-center flex-column gap-2'>
                    <div className='d-flex justify-content-center'>
                    <i className="pi  pi-trash trash-icon"></i>
                    </div>
                    <div className='d-flex justify-content-center'>
                        <span className='dlt-txt'>Are you sure you want to delete this Health Tip?</span>
                    </div>
                    <div className='d-flex justify-content-around mt-3'>
                        <Button
                        className='no-btn'
                        label='No'
                        onClick={() => setdeletevisible(false)}
                        >
                            
                        </Button>
                        <Button
                        className='yes-btn'
                        label='Yes'
                        onClick={()=>deleteProduct(id)}
                        >
                            
                        </Button>
                    </div>
                </div>
            </Dialog>
            
        </>
    );
}

export default Healthtips;
