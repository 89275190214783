
import React, { useState, useEffect, useRef } from 'react';
import { AgentsTable } from "../../components/agents/agent-table.components"
import Navbar from '../../components/ui/navbar/sidenav.components';
import { Button } from 'primereact/button';
import { InputSwitch } from "primereact/inputswitch";
import { Dialog } from 'primereact/dialog';
import "../agents/agents.css"
import { GetAllAgents } from '../../api/agents.api';
import AddModal from '../../components/add-agent/add-agent';
import EditModal from '../../components/edit-agent/EditModal';
import { AgentStatus } from '../../api/agents.api';
import { Toast } from 'primereact/toast';
export default function Agents() {

    const [loading, setLoading] = useState(false);
    const [customers, setCustomers] = useState(undefined);
    const [addShow, setAddShow] = useState(false);
    const [editShow, setEditShow] = useState(false);
    const [visible, setVisible] = useState(false);
    const EditStatus = async () => {
        const respData = await AgentStatus(agentObj._id, !agentObj.status);
        // console.log(respData);
        toastShow(respData.data.message)
        setVisible(false)
        refreshPage();
    }
    const refreshPage = () => {
        window.location.reload();
    }
    const footerContent = (

        <div>
            <Button label="Cancel" icon="pi pi-times" onClick={() => setVisible(false)} className="p-button-outlined p-button-rounded" />
            <Button label="Confirm" icon="pi pi-check" className='p-button-rounded p-button-success change-btn-login' onClick={() => { EditStatus() }} autoFocus />
        </div>
    );
    const toast = useRef<Toast>(null);
    const toastShow = (message: any) => {
        if (toast.current) {
            toast.current.show({
                severity: "info",
                summary: "Info",
                detail: message,
            });
        } else {
            console.log("toast issue");
        }
    };
    // const [deleteProductDialog, setDeleteProductDialog] = useState(false);
    // const [checked, setChecked] = useState<boolean>(true);

    // const [selectedRepresentative, setSelectedRepresentative] = useState(undefined);
    var width = window.screen.width;
    var c_rows = 10;
    if (width >= 1920) {
        c_rows = 15
    }
    else if (width >= 1400 || width <= 1600) {
        c_rows = 10
    }
    const [agentObj, setAgentObj] = useState({
        _id: "",
        name: "",
        email: "",
        city: "",
        mobile: "",
        status: false
    })
    const [lazyParams, setLazyParams] = useState({
        first: 0,
        rows: c_rows,
        page: 1,
        sortField: '',
        sortOrder: 0,
        filters: {
            'name': { value: '', matchMode: 'contains' },
            'email': { value: '', matchMode: 'contains' },
            'city': { value: '', matchMode: 'contains' },
            'mobile': { value: '', matchMode: 'contains' },
        }
    });

    let loadLazyTimeout: any = 0;

    useEffect(() => {
        loadLazyData();
    }, [lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        //imitate delay of a backend call
        loadLazyTimeout = setTimeout(async () => {
            console.log(lazyParams);
            const respObj = await GetAllAgents();
            console.log("Inside pending", respObj.data.data);
            setCustomers(respObj.data.data);
            // setTotalRecords(respObj.data.totalRecords);
            setLoading(false);
        }, 100);
    }

    const onPage = (event: any) => {
        setLazyParams(event);
    }

    const onSort = (event: any) => {
        setLazyParams(event);
    }

    const onFilter = (event: any) => {
        event['first'] = 0;
        setLazyParams(event);
    }
    const setEdit = (rowData: any) => {
        console.log(rowData)
        console.log(rowData.status);
        setAgentObj(rowData);

    }

    // const representativeBodyTemplate = (rowData:any) => {
    //     return (
    //         <React.Fragment>
    //             <img alt={rowData.representative.name} src={`https://www.primereact.org/images/avatar/${rowData.representative.image}`} width={32} style={{ verticalAlign: 'middle' }} />
    //             <span className="vertical-align-middle ml-2">{rowData.representative.name}</span>
    //         </React.Fragment>
    //     );
    // }

    // const countryBodyTemplate = (rowData:any) => {

    //     return (
    //         <React.Fragment>
    //             <img alt="flag" src="https://www.primereact.org/images/flag/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} width={30} />
    //             <span className="vertical-align-middle ml-2">{rowData.country.name}</span>
    //         </React.Fragment>
    //     );
    // }
    // const viewProduct = (product:any) => {
    //     // setDeleteProductDialog(true);
    // }
    const actionBodyTemplate = (rowData: any) => {
        return (
            <React.Fragment>
                <Button icon="pi pi-file-edit" className="p-button-rounded mr-2 view-bt" onClick={() => { setEdit(rowData); setEditShow(true) }} />
            </React.Fragment>
        );
    }
    const StatusBodyTemplate: React.FC = (rowData: any) => {
        const [status, setStatus] = useState(rowData.status)
        const ChangeStatus = (status: boolean) => {
            setEdit(rowData);
            setVisible(true)
            console.log(rowData);
            setStatus(!status)

        }



        return (
            <React.Fragment>
                <InputSwitch checked={status} onChange={(e: any) => ChangeStatus(e.value)} />
            </React.Fragment>
        );
    }
    return (
        <>
            <main>
                <div>
                    <Navbar />
                    <section className="page-content">
                        <div className="ym-content-table">
                            <div className="d-flex pb-2" style={{borderBottom:"1px solid #ececec"}} >
                                <div className="p-2">
                                    <h1 className='ym-portal-heading'>Agents</h1></div>
                                <div className="ml-auto ">
                                    <Button className="add_agent_btn d-flex justify-content-center" icon="pi pi-plus-circle" label='Add Agent' onClick={() => { setAddShow(true) }}></Button>
                                </div>
                            </div>
                            
                            <div className="p-3">
                                <AgentsTable
                                    customers={customers}
                                    first={lazyParams.first}
                                    sortField={lazyParams.sortField}
                                    sortOrder={lazyParams.sortOrder}
                                    // totalRecords={totalRecords} 
                                    onPage={onPage}
                                    onSort={onSort}
                                    onFilter={onFilter}
                                    filters={lazyParams.filters}
                                    loading={loading}
                                    actionBodyTemplate={actionBodyTemplate}
                                    statusBodyTemplate={StatusBodyTemplate} />
                            </div>
                        </div>
                    </section>
                    <AddModal show={addShow} setShow={setAddShow} />
                    <EditModal show={editShow} setShow={setEditShow} id={agentObj._id} name={agentObj.name} city={agentObj.city} mobile={agentObj.mobile} email={agentObj.email} />
                    <Dialog header="Confirm status change" visible={visible} style={{ width: '50vw' }} onHide={() => setVisible(false)} footer={footerContent} >
                        <p>
                            Are you sure you want to change {agentObj.name} status to {(!agentObj.status) ? "active" : "inactive"}?
                        </p>
                    </Dialog>
                </div>
            </main>

        </>


    );
}
