import axios from "axios";
import HttpInstance from "./http.api";
import { apiUrl } from "../config/constants";
const HttpInstanceImage=axios.create({
    baseURL: apiUrl,
    headers: {
      'Accept': '*/*',
     'Authorization': "Bearer "+localStorage.getItem('accessToken'),
     'Content-Type':'multipart/form-data'
    }
  })

export const ApproveRestaurant=(id:string)=>{
    const respData = HttpInstance.patch("/restaurant/approverestaurant?id="+id,{approvalstatus:1})
    return respData;
}

export const RejectRestaurant=(id:string,data:any)=>{
 
    const RespData = HttpInstance.patch("/restaurant/approverestaurant?id="+id,data)
    return RespData;
}

export const ApproveOrganicStore= (id:string) =>{
    const RespData = HttpInstance.patch("/organicstore/approveorganicstore?id="+id,{approvalstatus:1})
    return RespData;
}
export const RejectOrganicStore= (id:string,data:any) =>{
    const RespData = HttpInstance.patch("/organicstore/approveorganicstore?id="+id,data)
    return RespData;
}

export const Approvefoodevent= (id:string) =>{
    const RespData = HttpInstance.patch("/foodevents/approvefoodevent?id="+id,{approvalstatus:1})
    return RespData;
}
export const Rejectfoodevent= (id:string,data:any) =>{
    const RespData = HttpInstance.patch("/foodevents/approvefoodevent?id="+id,data)
    return RespData;
}
export const getCategories = ()=>{
    const respData = HttpInstance.get("/restaurant/categories")
    return respData
}
// export const EditRestaurantDetails = (id:string,Editobj:any) =>{
//     const respData = HttpInstanceImage.patch("/restaurant/updaterest?id="+id,Editobj)
//     return respData
// }
export const EditRestaurantDetails = async (id:string, formData:any) => {
      const response = await axios.patch(
        `${apiUrl}/restaurant/updaterest?id=${id}`,
        formData,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("accessToken"),
            "Content-Type": "multipart/form-data",
          },
        }
      );
      return response.data;
    } 