import React from 'react';
import "./detailsinfo.css";

interface props {
    registereddietitian: string;
    languages: string[];
    services: string[];
    about: string;

}

const DietitianInfo: React.FC<props> = ({ registereddietitian, languages, services, about }) => {
    return (
        <div>
            <div className='d-flex'>
                <div className='col-3'>
                    <h6 className="mb-1 sub-detail-head">Registered Dietitian</h6>
                    <p className='sub-detail-data'>{registereddietitian}</p>
                </div>

                <div className='col-4'>
                    <h6 className="mb-1 sub-detail-head">Languages</h6>
                    <p className='sub-detail-data'>{languages.join(", ")}</p>
                </div>
            </div>

            <div className='col pt-0'>
                <h6 className="mb-1 sub-detail-head">Services</h6>
                <p className='sub-detail-data'>{services.join(",")}</p>
            </div>
            <div className='col-5 pt-0'>
                <h6 className="mb-1 sub-detail-head">About</h6>
                <p className='sub-detail-data'>{about}</p>
            </div>

        </div>
    );
}

export default DietitianInfo;
