import HttpInstance from "./http.api";

export const GetData = async (params: any) => {
  const RespData = await HttpInstance.get("/settings/getalldata", {
    params: {
      type: params.type,
    },
  });
  return RespData.data;
};

export const AddData = async (resObj: any) => {
  const RespData = await HttpInstance.post("/settings/addsettings", resObj);
  return RespData.data;
};
export const settingStatus = (id: string, status: boolean) => {
  console.log("inside agent status", id, status);
  const respData = HttpInstance.post("/settings/settingstatus?id=" + id, {
    status: status,
  });
  return respData;
};
