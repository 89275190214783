import React,{useRef} from 'react'
import { Formik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from 'primereact/dialog';
import { Toast } from "primereact/toast";
import { EditAgent } from '../../api/agents.api';
import "../edit-agent/edit-model.css"
type props={
  show:boolean;
  setShow:Function;
  id:string;
  name:string;
  email:string;
  mobile:string;
  city:string;
}
const EditModal:React.FC<props> = ({id,show,setShow,name,email,mobile,city}) => {
    const toast = useRef<Toast>(null);
    const toastShow = (message: any) => {
      if (toast.current) {
        toast.current.show({
          severity: "info",
          summary: "Info",
          detail: message,
        });
      } else {
        console.log("toast issue");
      }
    };
  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is a required field")
      .email("Invalid email format"),
    name: Yup.string().required("Name is a required field"),
    mobile:Yup.string().required("Mobile number is required"),
    city:Yup.string().required("City is required")
  });
  const EditFooter=(
    <div>
            <Button label="Cancel" onClick={() => setShow(false)} className="p-button-outlined p-button-rounded" />
            <Button label="Save Changes" form="edit" type="submit"  className="p-button-rounded p-button-success change-btn-login" onClick={() => setShow(false)} autoFocus />
    </div>
  )
 
  const editAgent = async(id:string,values:any)=>{
    console.log("Inside Edit agent",id)
    const editObj={
        name:values.name,
        email:values.email,
        mobile:values.mobile,
        city:values.city
    }
    const respData = await EditAgent(id,editObj)
    console.log(respData.data)
    toastShow(respData.data.message)
    refreshPage()
  }

  const refreshPage = ()=>{
     window.location.reload();
  }
  return (
    <>
            <Toast ref={toast}/>
          <Dialog header="Edit Agent" visible={show} style={{ width: '30vw' }} onHide={() => setShow(false)} footer={EditFooter}>
          <Formik
                  validationSchema={schema}
                  initialValues={{name:name,city:city, email:email, mobile: mobile }}
                  onSubmit={(values) => {
                     (editAgent(id,values));
                  }}
                >
                  {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                  }) => (
                    
                    <div className="justify-content-center">
                      <br></br>
                    
                      <form id="edit" noValidate onSubmit={handleSubmit}>

                        <div className="flex flex-column">
                      <label htmlFor="name">Full Name</label>
                              <InputText
                                id="name"
                                type="name"
                                placeholder="Enter Name..."
                                name="name"
                                value={values.name}
                                onChange={handleChange}
                              />
                            <p className="error">
                              {errors.name && touched.name && errors.name}
                            </p>
                          </div>
                          <div className="flex flex-column">
                      <label htmlFor="email">Email</label>
                              <InputText
                                id="email"
                                type="email"
                                placeholder="Enter Email..."
                                name="email"
                                value={values.email}
                                onChange={handleChange}
                              />
                            <p className="error">
                              {errors.email && touched.email && errors.email}
                            </p>
                          </div>
                          <div className="flex flex-column">
                      <label htmlFor="mobile">Phone Number</label>
                              <InputText
                                id="mobile"
                                placeholder="Enter PhoneNo..."
                                name="mobile"
                                value={values.mobile}
                                onChange={handleChange}
                              />
                            <p className="error">
                              {errors.mobile &&
                                touched.mobile &&
                                errors.mobile}
                            </p>
                          </div>
                          <div className="flex flex-column">
                      <label htmlFor="city">City</label>
                              <InputText
                                id="city"
                                placeholder="Enter City..."
                                name="city"
                                value={values.city}
                                onChange={handleChange}
                              />
                            <p className="error">
                              {errors.city &&
                                touched.city &&
                                errors.city}
                            </p>
                          </div>
                      </form>
                    </div>
                  )}
                </Formik>
            </Dialog>
          
    </>
  )
} 

export default EditModal