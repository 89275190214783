import { Button } from 'primereact/button';
import { Dropdown } from 'primereact/dropdown';
import { InputText } from 'primereact/inputtext';
import { InputTextarea } from 'primereact/inputtextarea';
import { RadioButton } from 'primereact/radiobutton';
import React, { useEffect, useRef, useState } from 'react';
import { EditHealthtips } from '../../api/healthtips.api';
import { Toast } from 'primereact/toast';

type props = {
    seteditvisible: any,
    rowData:any,
    
}

const Edithealthtip:React.FC<props> = ({rowData,seteditvisible}) => {
    console.log("rowdata:",rowData)
    const initialCategories = [
        'Diabetes',
        'Cardiovascular Disease',
        'Hypertension (High Blood Pressure)',
        'Obesity',
        'Asthma',
        'Cancer (General Prevention)',
        'Depression & Anxiety',
        'Kidney Chronic Disease',
        'Gastrointestinal Disorders',
        'Rheumatoid Arthritis'
    ];

    const [categorylist, setcategorylist] = useState(initialCategories);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [status, setStatus] = useState('Active');
    const [imageUrl, setImageUrl] = useState('');
    const toast = useRef<Toast>(null);
    const show = (message: any, severity: any, summary: any) => {
        if (toast.current) {
          toast.current.show({
            severity: severity,
            summary: summary,
            detail: message,
          });
        } else {
          console.log("toast issue");
        }
      };

    const handleCategoryChange = (e: any) => {
        setSelectedCategory(e.value);
    };
    const handleStatusChange = (e: any) => {
        setStatus(e.value);
    };
    useEffect(() => {
        if (rowData) {
            setTitle(rowData.tipname || '');
            setDescription(rowData.description || '');
            setSelectedCategory(rowData.tipcategory || null);
            setImageUrl(rowData.tipimages || null);
            setStatus(rowData.tipstatus || 'Active');
        }
    }, [rowData]);

        const handleEditHealthTip = async (tipid: string) => {
        const editObj = {
                tipname: title,
                description: description,
                tipcategory: selectedCategory,
                tipimages: imageUrl,
                tipstatus: status
            };
    
            try {
                const response = await EditHealthtips(tipid, editObj);
                show("Healthtip Updated Successfully", "success", "Success");
                // seteditvisible(false)
            } catch (error) {
                console.error('Error updating health tip:', error);
             
            }
        };


    

    const [title, setTitle] = useState('');

    const handleTitleChange = (event: any) => {
        const inputValue = event.target.value;
        setTitle(inputValue);
    };

    const handleImageChange = (event: any) => {
        const inputValue = event.target.value;
        setImageUrl(inputValue);
    };
    const maxTitleLength = 50;
    const titleLength = title.length;

    const [description, setDescription] = useState('');

    const handleDescriptionChange = (event: any) => {
        const inputValue = event.target.value;
        setDescription(inputValue);
    };

    const maxDescriptionLength = 130;
    const descriptionLength = description.length;

  return (
    <div className='d-flex flex-column gap-2 mt-3'>
           <Toast ref={toast} position="top-right" />
    <div className='title-input'>
        <div className='mb-1 d-flex justify-content-between'>
            <label className='tips-page-head'>Title</label>
            <span className='count-text'>
                {titleLength}/{maxTitleLength}
            </span>
        </div>
        <div className='flex flex-column'>
            <InputText
                placeholder='Enter Title'
                maxLength={maxTitleLength}
                value={title}
                onChange={handleTitleChange}
            />
        </div>
    </div>
    <div className='desc-input'>
        <div className='mb-1 d-flex justify-content-between' >
            <label className='tips-page-head'>Description</label>
            <span className='count-text'>
                {descriptionLength}/{maxDescriptionLength}
            </span>
        </div>
        <div className='flex flex-column'>
            <InputTextarea
                maxLength={maxDescriptionLength}
                placeholder='Enter description'
                value={description}
                onChange={handleDescriptionChange}
                rows={3}
                cols={30}
            />
          
        </div>
    </div>
    <div className='image-input'>
        <div className='mb-1'>
            <label className='tips-page-head'>
                Image
            </label>
        </div>
        <div className="flex flex-column">
            <InputText
                placeholder="Enter image url"
              value={imageUrl}
           onChange={handleImageChange}
            />
        </div>

    </div>
    <div className='category-dropdown'>
        <div className='mb-1'>
            <label className='tips-page-head'>
                Category
            </label>
        </div>
        <div className="flex flex-column">
            <Dropdown
                placeholder="Select"
                options={categorylist}
                value={selectedCategory}
                onChange={handleCategoryChange}
            />
        </div>

    </div>
    <div className='status-radio'>
        <div>
            <label className='tips-page-head'>
                Status
            </label>
        </div>
        <div className="flex  gap-4">
            <div className="p-field-radiobutton">
                <RadioButton inputId="active" name="status" value="Active" onChange={handleStatusChange} checked={status === 'Active'} />
                <label className='radio-check' htmlFor="active">Active</label>
            </div>
            <div className="p-field-radiobutton">
                <RadioButton inputId="inactive" name="status" value="Inactive" onChange={handleStatusChange} checked={status === 'Inactive'} />
                <label className='radio-check' htmlFor="inactive">Inactive</label>
            </div>
        </div>

    </div>
    <hr style={{ color: "#f5f5f5", height: "1px", margin: "0.5rem" }} />
    <div className='d-flex justify-content-end'>
        <Button
            className="tip-submit-button"
            label="Update"
            onClick={() => handleEditHealthTip(rowData._id)}
        ></Button>
    </div>
</div>
  );
}

export default Edithealthtip;
