import React, { useState, useEffect } from "react";
import { PendingTable } from "../../../components/pending-restaurant/pending-table.components";
import Navbar from "../../../components/ui/navbar/sidenav.components";
import { Button } from "primereact/button";
import "../pending-restaurants/pending-restaurants.css";
import { GetTableData } from "../../../api/pendingTable.api";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "primereact/tooltip";
export default function Pending() {
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [customers, setCustomers] = useState(undefined);
  // const [deleteProductDialog, setDeleteProductDialog] = useState(false);
  // useEffect(() => {
  //     setTimeout(async () => {
  //     const respObj=await GetPending();
  //     console.log(respObj);
  //     setCustomers(respObj.data.data)
  //     }, 400)},[]);
  // const [selectedRepresentative, setSelectedRepresentative] = useState(undefined);
  const navigate = useNavigate();
  // var width = window.screen.width;
  // var c_rows = 10;
  const rowsNo = () => {
    var width = window.screen.width;
    if (width >= 1920) {
      return 15;
    } else {
      return 10;
    }
  };
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rowsNo(),
    page: 0,
    sortField: "",
    sortOrder: 0,
    filters: {
      name: { value: "", matchMode: "contains" },
      city: { value: "", matchMode: "contains" },
      // foodtype: { value: "", matchMode: "contains" },
      agentname: { value: "", matchMode: "contains" },
    },
  });

  let loadLazyTimeout: any = 0;

  useEffect(() => {
    loadLazyData();
  }, [lazyParams]); // eslint-disable-line react-hooks/exhaustive-deps

  const loadLazyData = () => {
    setLoading(true);

    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }

    //imitate delay of a backend call
    loadLazyTimeout = setTimeout(async () => {
      // CustomerService.getCustomers({ lazyEvent: JSON.stringify(lazyParams) }).then(data => {
      // setTotalRecords(data.totalRecords);
      // setCustomers(data.customers);
      // setLoading(false);
      // console.log(data);
      // });
      const respObj = await GetTableData(lazyParams, 0);
      setCustomers(respObj.data.data.restaurants);
      setTotalRecords(respObj.data.data.totalRecords);
      setLoading(false);
    }, 100);
  };

  const onPage = (event: any) => {
    setLazyParams(event);
  };

  const onSort = (event: any) => {
    setLazyParams(event);
  };

  const onFilter = (event: any) => {
    event["first"] = 0;
    setLazyParams(event);
  };

  const representativeBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <img
          alt={rowData.representative.name}
          src={`https://www.primereact.org/images/avatar/${rowData.representative.image}`}
          width={32}
          style={{ verticalAlign: "middle" }}
        />
        <span className="vertical-align-middle ml-2">
          {rowData.representative.name}
        </span>
      </React.Fragment>
    );
  };

  const countryBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
        <img
          alt="flag"
          src="https://www.primereact.org/images/flag/flag_placeholder.png"
          className={`flag flag-${rowData.country.code}`}
          width={30}
        />
        <span className="vertical-align-middle ml-2">
          {rowData.country.name}
        </span>
      </React.Fragment>
    );
  };
  const viewProduct = (product: any) => {
    // setDeleteProductDialog(true);
    navigate(`/restaurants/pending/details/${product._id}`);
    // window.open(`/pending/details/${product._id}`);
  };
  const actionBodyTemplate = (rowData: any) => {
    return (
      <React.Fragment>
                     <Tooltip
                      target=".p-dt-tooltip"
                      // content="View"
                      mouseTrack
                      mouseTrackLeft={10}
                    />
        <Button
          icon="pi pi-eye"
          label="View Details"
          className="p-button-rounded mr-2 view-bt p-dt-tooltip"
          onClick={() => viewProduct(rowData)}
        />
      </React.Fragment>
    );
  };
  return (
    <>
      <main>
        <div>
          <Navbar />
          <section className="page-content">
          <div className="ym-content-table">
          <div className="page_title d-sm-flex">
              <div className="d-flex align-items-center FE-heading-nav">
                <span className="event-span-head">Pending Restaurants</span></div>
              </div>
              <hr className="mb-0"/>
              <div className="card pt-3 pb-3 pl-0 pr-0" style={{border:"none"}}>
                <PendingTable
                  key={customers}
                  customers={customers}
                  first={lazyParams.first}
                  sortField={lazyParams.sortField}
                  sortOrder={lazyParams.sortOrder}
                  totalRecords={totalRecords}
                  onPage={onPage}
                  onSort={onSort}
                  onFilter={onFilter}
                  filters={lazyParams.filters}
                  loading={loading}
                  countryBodyTemplate={countryBodyTemplate}
                  representativeBodyTemplate={representativeBodyTemplate}
                  actionBodyTemplate={actionBodyTemplate}
                />
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
