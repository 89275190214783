import HttpInstance from "./http.api";

export const GetEventsTableData = async (params:any,approvalstatus:number)=>{
    const RespData= await HttpInstance.post("/foodevents/foodeventstable",{...params,approvalstatus:approvalstatus})
    console.log(RespData.data);
    return RespData.data;
}
export const GetEventbyID = (id:string) =>{
    const RespData = HttpInstance.get("/foodevents/getfoodeventbyid?id="+id)
    return RespData
 }