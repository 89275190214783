import React, { useRef } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { Toast } from "primereact/toast";
import { AddAgent } from "../../api/agents.api";
import "../add-agent/add-agent.css"
type props = {
  show: boolean;
  setShow: Function;
};
const AddModal: React.FC<props> = ({ show, setShow }) => {
  const toast = useRef<Toast>(null);
  const toastShow = (message: any) => {
    if (toast.current) {
      toast.current.show({
        severity: "info",
        summary: "Info",
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };
  // const formRef = useRef(null);
  const schema = Yup.object().shape({
    email: Yup.string()
      .required("Email is a required field")
      .email("Invalid email format"),
    name: Yup.string().required("Name is a required field"),
    mobile: Yup.string().required("Mobile number is required"),
    city: Yup.string().required("City is required"),
  });

  const AddFooter = (
    <div>
      <Button
        label="Save"
        form="add"
        type="submit"
        className="p-button-rounded p-button-success save-btn-login"
      ></Button>
    </div>
  );
  const addAgent = async (values: any) => {
    console.log("Inside Add agent", values.name);
    const respData = await AddAgent(values);
    console.log(respData);
    toastShow(respData.data.message);
    setShow(false);
    refreshPage()
  };
  const refreshPage = ()=>{
    window.location.reload();
 }
  return (
    <>
      <Toast ref={toast} />
      <Dialog
        header="Add Agent"
        visible={show}
        style={{ width: "30vw" }}
        onHide={() => setShow(false)}
        footer={AddFooter}
      >
        <Formik
          validationSchema={schema}
          initialValues={{ name: "", city: "", email: "", mobile: "" }}
          onSubmit={(values) => {
            addAgent(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <div className="justify-content-center">
              <br></br>
              {/* <p className="logo_sub_text">
                        <span style={{ fontWeight: "bold" }}>{heading}</span>
                      </p> */}
              <form id="add" noValidate onSubmit={handleSubmit}>
                      <div className="flex flex-column">
                      <label htmlFor="name">Full Name</label>
                            <InputText
                              id="name"
                              type="name"
                              placeholder="Enter Name..."
                              name="name"
                              value={values.name}
                              onChange={handleChange}
                            />
                          <p className="error">
                            {errors.name && touched.name && errors.name}
                          </p>
                          </div>
               
                      <div className="flex flex-column">
                      <label htmlFor="email">Email</label>
                            <InputText
                              id="email"
                              type="email"
                              placeholder="Enter Email..."
                              name="email"
                              value={values.email}
                              onChange={handleChange}
                            />
                          <p className="error">
                            {errors.email && touched.email && errors.email}
                          </p>
                      </div>
                      <div className="flex flex-column">
                      <label htmlFor="mobile">Phone Number</label>
                            <InputText
                              id="mobile"
                              placeholder="Enter PhoneNo..."
                              name="mobile"
                              value={values.mobile}
                              onChange={handleChange}
                            />
                          <p className="error">
                            {errors.mobile && touched.mobile && errors.mobile}
                          </p>
                      </div>
                 
                      <div className="flex flex-column">
                      <label htmlFor="city">City</label>
                            <InputText
                              id="city"
                              placeholder="Enter City..."
                              name="city"
                              value={values.city}
                              onChange={handleChange}
                            />
                          <p className="error">
                            {errors.city && touched.city && errors.city}
                          </p>
                      </div>
              </form>
            </div>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddModal;

