import React, { useState } from "react";
import "./RestaurantDetailsScreen.css";
import { Card } from "primereact/card";
import Pillsdetails from "../detail-pills/detail-pills.components";
import { Tooltip } from 'primereact/tooltip';
import Zomatoicon from "../../assets/icons/zomatoicon.svg"
import Youtubeicon from "../../assets/icons/youtubeicon.png"
import Swiggyicon from "../../assets/icons/swiggyicon.svg"
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import YouTube, { YouTubeProps } from "react-youtube";
import Dineout from "../../assets/images/Dineout.svg"
import { Skeleton } from "primereact/skeleton";

type props = {
  cuisine: any[];
  specialitems: string[];
  preference: string[];
  foodtype: any[];
  eaterytype: any[];
  ambience: string;
  values: any[];
  serving: string[];
  serviceoptions: string[];
  phoneno: string;
  address: string;
  sourcelink: string;
  images: string[];
  description: string[];
  whyyeldam: string[];
  zomatolink: string;
  swiggylink: string;
  dineoutlink: string;
  maplink: string;
  email: string;
  website: string;
  city: string;
  pincode: string;
  state: string;
  mealoftheday: string[];
  averagecost: string;
  features: string[];
  diettype: any[];
  sizeofestablishment: string;
  agentname: string;
};
const Restaurantdetails: React.FC<props> = ({
  cuisine,
  specialitems,
  preference,
  foodtype,
  eaterytype,
  ambience,
  values,
  serving,
  serviceoptions,
  phoneno,
  address,
  sourcelink,
  description,
  whyyeldam,
  zomatolink,
  swiggylink,
  dineoutlink,
  maplink,
  email,
  website,
  city,
  pincode,
  state,
  mealoftheday,
  averagecost,
  features,
  diettype,
  sizeofestablishment,
  agentname,
}) => {
  const [lvisible, setLvisible] = useState(false);
  const ss_timelabel = "<h6><b>No Timings</b></h6>";

  const s_timelabel = "<h5><b>Opening Hours</b></h5>";
  var timings_s: any = "";
 
  const ss_timings = s_timelabel + timings_s;
  console.log(s_timelabel + timings_s);
  const [visible, setVisible] = useState(false);
  let youtubeurl = "";

  const onPlayerReady: YouTubeProps['onReady'] = (event) => {
    event.target.pauseVideo();
  }

  const opts: YouTubeProps['opts'] = {
    height: '390',
    width: '580',
    playerVars: {
      autoplay: 0,
    },
  };

  if (sourcelink?.length > 1) {
    var videoid = sourcelink.match(/(?:https?:\/{2})?(?:w{3}\.)?youtu(?:be)?\.(?:com|be)(?:\/watch\?v=|\/)([^\s&]+)/);
    if (videoid != null) {
      console.log("video id = ", videoid[1]);
      youtubeurl = videoid[1];
    } else {
      console.log("The youtube url is not valid.");
    }
  }

  return (
    <>
       <div className="Main">
        <div className="row">
          <div className="column1 col-lg-4 d-flex flex-column gap-3">
            {/* preferences */}
            <div className="gap-2 d-flex align-items-start">
              <div className="preference mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Preference</h6>
                    <div className="d-flex">
                    {preference?.map((tag, i) => (
                      <div key={"Preference" + i} className="">
                        &nbsp;
                        <span className="span-data" >
                          {i > 0 && ", "}
                          {tag.trim()}
                        </span>
                      </div>
                    ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* serving */}
            <div className="gap-2 d-flex align-items-start">
              <div className="serving mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Servings</h6>
                    <div className="d-flex">
                    {serving.map((tag, i) => (
                      <div className="" key={"serving" + i}>
                        &nbsp;
                        <span className="span-data" >
                          {i > 0 && ", "}
                          {tag.trim()}
                        </span>
                      </div>
                    ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* meal of the day */}
            <div className="gap-2 d-flex align-items-start">
              <div className="meal mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Meal Of The Day</h6>
                    <div className="d-flex">
                      {mealoftheday.map((tag, i) => (
                        <div className="" key={"mealoftheday" + i}>
                          <span className="span-data" >
                            {i > 0 && ", "}
                            {tag.trim()}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* special items */}
            <div className="gap-2 d-flex align-items-start">
              <div className="meal mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Special Items</h6>
                    <div className="items-display">
                      {specialitems.map((tag, i) => (
                        <div className="d-flex" key={"specialitems" + i}>
                          <span className="span-data" >
                            {i > 0 && ", "}
                            {tag.trim()}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="column2 col-lg-4 d-flex flex-column gap-3">
            {/* average cost */}
            <div className="gap-2 d-flex align-items-start">
              <div className="about">
                {averagecost === "NaN" ? (
                  ""
                ) : (
                  <>
                    {lvisible ? (
                      <>
                        <Skeleton width="10rem" className="mb-2"></Skeleton>
                        <Skeleton width="100%" className="mb-2"></Skeleton>
                      </>
                    ) : (
                      <>
                        <h6 className="mb-2">Average Cost (per 2 persons)</h6>
                        <p className="span-data">₹{averagecost}</p>
                      </>
                    )}
                  </>
                )}
              </div>
            </div>
            {/* Ambience */}
            <div className="gap-2 d-flex align-items-start">
              <div className="ambience mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <div className="gap-2 d-flex align-items-start">
                      <div className="ambience">
                        <h6 className="mb-1">Ambience</h6>

                        <span className="span-data">{ambience ? ambience : "--"}</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* eatery type */}
            <div className="gap-2 d-flex align-items-start">
              <div className="eaterytype mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Eatery Type</h6>
                    <div className="d-flex">
                    {eaterytype.map((tag, i) => (
                      <div className="" key={"eaterytype" + i}>
                        <span className="span-data" >
                          {i > 0 && ", "}
                          {tag.name}
                        </span>
                      </div>
                    ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* food type */}
            <div className="gap-2 d-flex align-items-start">
              <div className="foodtype mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Food Type</h6>
                    <div className="d-flex">
                      {foodtype.map((a, i) => (
                        <div className="" key={"foodtype" + i}>
                          <span className="span-data" >
                            {i > 0 && ", "}
                            {a.name}
                          </span>
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
          <div className="column3 col-lg-4 d-flex flex-column gap-3">
            {/* diet type */}
            <div className="gap-2 d-flex align-items-start">
              <div className="eaterytype mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Diet Type</h6>
                    <div className="d-flex">
                    {diettype.map((tag, i) => (
                      <div className="" key={"diettype" + i}>
                        <span className="span-data" >
                          {i > 0 && ", "}
                          {tag.name}
                        </span>
                      </div>
                    ))}
                    </div>
                  </>
                )}
              </div>
            </div>
            {/* Values */}
            <div className="gap-2 d-flex align-items-start">
              {values[0] === "None" ? (
                ""
              ) : (
                <>
                  <div className="values mb-3">
                    {lvisible ? (
                      <>
                        <Skeleton width="10rem" className="mb-2"></Skeleton>
                        <Skeleton width="100%" className="mb-2"></Skeleton>
                      </>
                    ) : (
                      <>
                        <h6 className="mb-1">Values</h6>
                        <div className="d-flex">
                        {values.map((tag, i) => (
                          <div className="" key={"values" + i}>
                            <span className="span-data" >
                              {i > 0 && ", "}
                              {tag.name.trim()}
                            </span>
                          </div>
                        ))}
                        </div>
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            {/* Cuisines */}
            <div className="gap-2 d-flex align-items-start">
              <div className="eaterytype mb-3">
                {lvisible ? (
                  <>
                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                    <Skeleton width="100%" className="mb-2"></Skeleton>
                  </>
                ) : (
                  <>
                    <h6 className="mb-1">Cuisines</h6>
                    <div className="cuisine-display">          
                                {cuisine.map((tag, i) => (
                      <div className="d-flex" key={"cuisine" + i}>
                        <span className="span-data" >
                          {i > 0 && ", "}
                          {tag.name.trim()}
                        </span>
                      </div>
                    ))}
                    </div>

                  </>
                )}
              </div>
            </div>
            {/* service options */}
            <div className="gap-2 d-flex align-items-start">
                
                  <div className="serviceoptions mb-3">
                    {lvisible ? (
                      <>
                        <Skeleton width="10rem" className="mb-2"></Skeleton>
                        <Skeleton width="100%" className="mb-2"></Skeleton>
                      </>
                    ) : (
                      <>
                        <h6 className="mb-1">Service Options</h6>
                        <div className="d-flex">
                        {serviceoptions.map((tag, i) => (
                          <div className="" key={"serviceoptions" + i}>
                          
                            <span
                              className="span-data"
                              
                            >
                              {i > 0 && ","}
                              {tag.trim()}
                            </span>
                          </div>
                        ))}
                        </div>
                      </>
                    )}
                  </div>
                </div>
          </div>
        </div>
      </div>
      {/* </div> */}
    </>
  );
};

export default Restaurantdetails;