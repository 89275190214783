import React from 'react';
import './App.css';
import { AppRouter } from './components/router/AppRouter';

import "../src/assets/css/colors.css";
// import "../src/assets/css/bootstrap-5/css/bootstrap.min.css";
import "../src/assets/css/styles.css";
import "../src/assets/js/main.js"
import "primereact/resources/themes/saga-blue/theme.css";
import 'primereact/resources/primereact.css';                       // core css
// import "primereact/resources/primereact.min.css";
import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
function App() {
  return (
    <>
            <AppRouter />
</>
  );
}

export default App;
