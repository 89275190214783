import HttpInstance from "./http.api";


export const GetDietitianTableData = (params:any,approvalstatus:number)=>{
    const RespData= HttpInstance.post("/dietitians/admindietitiantable",{...params,approvalstatus:approvalstatus})
    return RespData;
 }
 export const GetDietitiandetailsbyID = (id:string) =>{
    const RespData = HttpInstance.get("/dietitians/getdietitianbyid?id="+id)
    return RespData
 }

 export const ApproveDietitian= (id:string) =>{
   const RespData = HttpInstance.patch("/dietitians/approvedietitian?id="+id,{approvalstatus:1})
   return RespData;
}
export const RejectDietitian= (id:string,data:any) =>{
   const RespData = HttpInstance.patch("/dietitians/approvedietitian?id="+id,data)
   return RespData;
}
