import React, { useEffect, useState } from "react";
import Navbar from "../../components/ui/navbar/sidenav.components";
import { Button } from "primereact/button";
import { Calendar } from "primereact/calendar";
import "./Analytics.css";
import { ProgressBar } from "primereact/progressbar";
import Doughnutchart from "../../components/ui/charts/doughnut-chart/doughnut-chart.components";
import countrypic from "../../assets/images/mapcountries.png";
import { Chart } from 'primereact/chart';

import {
  CardsDataGraph,
  GetCardsData,
  GetMostSearched,
  TopBrowsers,
  TopDevices,
  TopPages,
  TopPlatforms,
  TopReferrers,
  TopVisitorCountries,
} from "../../api/userstatistics.api";
interface WordData {
  _id: string;
  Count: number;
}
const Analytics: React.FC<any> = () => {
  const [fromdate, setfromDate] = useState<any>();
  const [todate, settoDate] = useState<any>();
  const [chartData, setChartData] = useState({});
  const [chartOptions, setChartOptions] = useState({});
  const [devicechartData, setDeviceChartData] = useState({});
  const [devicechartOptions, setDeviceChartOptions] = useState({});
  const [topbrowsers, setTopbrowsers] = useState<any>();
  const [toppages, setToppages] = useState<any>();
  const [getMostSearchedData, setGetMostSearchedData] = useState<
    WordData[] | null
  >(null);
  const [topreferrers, setTopreferrers] = useState<any>();
  const [topvisitors, setTopvisitors] = useState<any>();
  const [lineData, setLineData] = useState({});
  const [lineOptions, setLineOptions] = useState({});
  const [visitors, setvisitors] = useState<any>();
  const [visits, setVisits] = useState<any>();
  const [borderColors, setBorderColors] = useState('#9BDFC4');
  const [backgroundColor, setBackgroundColor] = useState('rgba(217, 255, 233, 0.3)');


  const Topbrowsers = async () => {
    try {
      const topbrowsers = await TopBrowsers();
      setTopbrowsers(topbrowsers.data.data);
    } catch (err) {
      console.log(err);
    }
  };
  const toppagesdata = async () => {
    try {
      const Toppages = await TopPages();
      setToppages(Toppages.data.data);
    } catch (err: any) {
      console.log(err);
    }
  };
  const GetMostSearchedData = async () => {
    try {
      const getMostSearched = await GetMostSearched();
      setGetMostSearchedData(getMostSearched.data.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  const Toprefferers = async () => {
    try {
      const gettoprefferers = await TopReferrers();
      setTopreferrers(gettoprefferers.data.data);
    } catch (err: any) {
      console.log(err);
    }
  };
  const Topvisitors = async () => {
    try {
      const gettopvisitors = await TopVisitorCountries();
      setTopvisitors(gettopvisitors.data.data);
    } catch (err: any) {
      console.log(err);
    }
  };

  const CardsData=async()=>{
    try{
const cardsdata=await GetCardsData();
setvisitors(cardsdata.data.uniqueVisitors)
setVisits(cardsdata.data.visits)
    }catch(err:any){
      console.log(err)
    }
  }
  const Topplatforms = async () => {
    try {
      const topplatforms = await TopPlatforms();
      const platformsdata = {
        labels: topplatforms.data.data.map((report: any) => report.platform),
        datasets: [
          {
            data: topplatforms.data.data.map(
              (report: any) => report.percentage
            ),
            backgroundColor: [
              "#62B2FD",
              "#9BDFC4",
              "#FAAFBC",
              "#B1ABF8",
              "#ACDDDE",
              "#F7D8BA",
              "#CEC9DF",
              "#E1E1E1",
              "#C8DCB8",
              "#A1A1D4",
            ],
            hoverBackgroundColor: [
              "#62B2FD",
              "#9BDFC4",
              "#FAAFBC",
              "#B1ABF8",
              "#ACDDDE",
              "#F7D8BA",
              "#CEC9DF",
              "#E1E1E1",
              "#C8DCB8",
              "#A1A1D4",
            ],
            borderWidth: 8,
          },
        ],
      };
      const options = {
        cutout: "60%",
        plugins: {
          legend: {
            display: false,
            position: "right",
            labels: {
              color: "#3c3c3c",
              font: {
                size: 12,
              },
              pointStyle: "circle",
            },
          },
        },
        onResize: (chart: any, size: any) => {
          chart.canvas.parentNode.style.width = "200px";
          chart.canvas.parentNode.style.height = "200px";
        },
      };

      setChartData(platformsdata);
      setChartOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error accordingly
    }
  };
  const Topdevices = async () => {
    try {
      const topdevices = await TopDevices();
      const topdevicesdata = {
        labels: topdevices.data.data.map((report: any) => report.device),
        datasets: [
          {
            data: topdevices.data.data.map((report: any) => report.percentage),
            backgroundColor: [
              "#62B2FD",
              "#9BDFC4",
              "#FAAFBC",
              "#B1ABF8",
              "#ACDDDE",
              "#F7D8BA",
              "#CEC9DF",
              "#E1E1E1",
              "#C8DCB8",
              "#A1A1D4",
            ],
            hoverBackgroundColor: [
              "#62B2FD",
              "#9BDFC4",
              "#FAAFBC",
              "#B1ABF8",
              "#ACDDDE",
              "#F7D8BA",
              "#CEC9DF",
              "#E1E1E1",
              "#C8DCB8",
              "#A1A1D4",
            ],
            borderWidth: 8,
          },
        ],
      };
      const options = {
        cutout: "60%",
        plugins: {
          legend: {
            display: false,
            position: "right",
            labels: {
              color: "#3c3c3c",
              font: {
                size: 12,
              },
              pointStyle: "circle",
            },
          },
        },
        onResize: (chart: any, size: any) => {
          chart.canvas.parentNode.style.width = "200px";
          chart.canvas.parentNode.style.height = "200px";
        },
      };

      setDeviceChartData(topdevicesdata);
      setDeviceChartOptions(options);
    } catch (error) {
      console.error("Error fetching data:", error);
      // Handle error accordingly
    }
  };
  
  
  const updateChart = (borderColor:any, backgroundColor:any,chartdata: any = {},label:any={}) => {
    const documentStyle = getComputedStyle(document.documentElement);
    const textColor = documentStyle.getPropertyValue('--text-color');
    const textColorSecondary = documentStyle.getPropertyValue('--text-color-secondary');
    const surfaceBorder = documentStyle.getPropertyValue('--surface-border');
   
    const canvas = document.createElement('canvas');
    const ctx = canvas.getContext('2d');
   
    if (!ctx) {
        console.error("Canvas context is not available.");
        return;
    }

    const gradient = ctx.createLinearGradient(0, 0, 0, 400);
    gradient.addColorStop(0, backgroundColor);
    gradient.addColorStop(1, 'rgba(217, 217, 217, 0.2)');

    const graphdata = {
        labels: chartdata.data?.map((item: any) => item.date),
        datasets: [{
            label: label,
            data: chartdata.data?.map((item: any) => item.count),
            fill: true,
            borderColor: borderColor,
            tension: 0.4,
            backgroundColor: gradient
        }]
    };

    const options = {
        maintainAspectRatio: false,
        aspectRatio: 0.6,
        plugins: {
            legend: {
                display: false,
                labels: {
                    color: textColor
                }
            }
        },
        scales: {
            x: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            },
            y: {
                ticks: {
                    color: textColorSecondary
                },
                grid: {
                    color: surfaceBorder
                }
            }
        },
        onResize: (chart: any, size: any) => {
            chart.canvas.parentNode.style.width = "100%";
            chart.canvas.parentNode.style.height = "429px";
        },
    };

    setLineData(graphdata);
    setLineOptions(options);
}



  useEffect(() => {
    Topbrowsers();
    Topplatforms();
    Topdevices();
    toppagesdata();
    GetMostSearchedData();
    Toprefferers();
    Topvisitors();
    CardsData();
   
  }, []);

  useEffect(() => {
    updateChart(borderColors,backgroundColor);
}, [borderColors,backgroundColor]);


  const handlepeople = () => {

    setBorderColors('#9BDFC4');
    setBackgroundColor('rgba(217, 255, 233, 0.3)');
  }
  const handlevisitors = async () => {
    setBackgroundColor('rgba(177, 171, 248, 0.3)');
    setBorderColors('#B1ABF8');
    try {
      const chartdata = await CardsDataGraph('visitors');
      updateChart('#B1ABF8', 'rgba(177, 171, 248, 0.3)', chartdata.data,'Visitors');
    } catch (err) {
      console.log(err);
    }
  }
  
  const handleaveragetime = () => {
    setBackgroundColor('rgba(98,178,253, 0.3)');
    setBorderColors('#62B2FD');

  }
  const handlevisits = async() => {
    setBorderColors('#E4B086');
    setBackgroundColor('rgba(228,176,134, 0.3)');
    try {
      const chartdata = await CardsDataGraph('visits');
      updateChart('#E4B086', 'rgba(228,176,134, 0.3)', chartdata.data,'Visits');
    } catch (err) {
      console.log(err);
    }
  }
  const handlenewsigns = () => {
    setBorderColors('#6C84B5');
    setBackgroundColor('rgba(108,132,181, 0.3)');
  }
  const handleresponse = () => {
    setBorderColors('#C2C2C2');
    setBackgroundColor('rgba(194,194,194, 0.3)');
  }


  return (
    <>
      <main>
        <div>
          <Navbar />
          <section className="page-content">
            <div className="ym-content-table">
              <div className="d-flex">
                <div className="p-2">
                  <h1 className="ym-portal-heading">Analytics</h1>
                </div>
                <div className="ml-auto d-flex gap-2 align-items-center">
                  <div
                    className="d-flex align-items-center calen"
                    style={{ width: "125px", height: "32px" }}
                  >
                    <Calendar
                      value={fromdate}
                      // onChange={(e) => setfromDate(e.target.value)}
                      showIcon
                      placeholder="From Date"
                    // dateFormat="dd/mm/yy"
                    />
                  </div>
                  <div
                    className="d-flex align-items-center calen"
                    style={{ width: "125px", height: "32px" }}
                  >
                    <Calendar
                      value={todate}
                      // onChange={(e) => settoDate(e.target.value)}
                      showIcon
                      placeholder="To Date"
                    // dateFormat="dd/mm/yy"
                    />
                  </div>
                  <Button
                    className="p-button-rounded p-button-success btn_submit"
                    label="Submit"
                  // onClick={() => addProduct()}
                  ></Button>
                </div>
              </div>
              <hr />
              <div className="col-lg-12 d-flex mb-4">
                <div className="col-lg-2" onClick={handlepeople}>
                  <div className="analytics-people-div p-2">
                    <p className="people-count">12</p>
                    <p className="people-text">People on site</p>
                  </div>
                </div>
                <div className="col-lg-2" onClick={handlevisitors}>
                  <div className="analytics-visitors-div d-flex justify-content-between  p-2">
                    <div>
                      <p className="people-count">{visitors}</p>
                      <p className="people-text">Visitors</p>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <div className="mr-3 mt-2 d-flex justify-content-center">
                        <i
                          className="pi pi-arrow-up"
                          style={{ color: "#137246" }}
                        ></i>
                      </div>
                      <div className="mr-3 d-flex align-items-end">
                        <p className="percentage">+12%</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2" onClick={handlevisits}>
                  <div className="analytics-visits-div d-flex justify-content-between  p-2">
                    <div>
                      <p className="people-count">{visits}</p>
                      <p className="people-text">Visits</p>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <div className="mr-3 mt-2 d-flex justify-content-center">
                        <i
                          className="pi pi-arrow-up"
                          style={{ color: "#137246" }}
                        ></i>
                      </div>
                      <div className="mr-3 d-flex align-items-end">
                        <p className="percentage">+20%</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2" onClick={handleaveragetime}>
                  <div className="analytics-time-div p-2">
                    <p className="people-count">02:48</p>
                    <p className="people-text">Average time on site</p>
                  </div>
                </div>
                <div className="col-lg-2" onClick={handlenewsigns}>
                  <div className="analytics-signs-div d-flex justify-content-between  p-2">
                    <div>
                      <p className="people-count">6</p>
                      <p className="people-text">New sign ups</p>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <div className="mr-2 mt-2 d-flex justify-content-center">
                        <i
                          className="pi pi-arrow-down"
                          style={{ color: "#D83B01" }}
                        ></i>
                      </div>
                      <div className="mr-2 d-flex align-items-end">
                        <p className="percentage" style={{ color: "#D83B01" }}>
                          -04%
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-2" onClick={handleresponse}>
                  <div className="analytics-response-div d-flex justify-content-between  p-2">
                    <div>
                      <p className="people-count mb-0">
                        24<span style={{ fontSize: "12px" }}>ms</span>
                      </p>
                      <p className="people-text">Response Time</p>
                    </div>
                    <div className="d-flex flex-column justify-content-between">
                      <div className=" mt-2 d-flex justify-content-center">
                        <i
                          className="pi pi-arrow-up"
                          style={{ color: "#137246" }}
                        ></i>
                      </div>
                      <div className=" d-flex align-items-end">
                        <p className="percentage">+5%</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className='col-lg-12 mb-4'>
                <div>
                  <Chart type="line" data={lineData} options={lineOptions} />
                </div>
              </div>
              <div className="d-flex gap-1  p-2 mb-4 col-lg-12">
                <div className="col-lg-4">
                  <div className="top-three-divs p-3">
                    <p className="three-heads">Top Browsers</p>
                    <div className="d-flex flex-column gap-3">
                      {topbrowsers &&
                        topbrowsers.map((browser: any, index: any) => (
                          <div key={index} className={`${browser.browser.toLowerCase()}-div chrome-div`}>
                            <div className="chrome-head d-flex justify-content-between align-items-center">
                              <span className="browsehead">{browser.browser}</span>
                              <span className="browsepercentage">{browser.percentage}%</span>
                            </div>
                            <ProgressBar
                              value={browser.percentage}
                              className="p-progressbar"
                            ></ProgressBar>
                          </div>
                        ))}
                    </div>

                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="top-three-divs p-3">
                    <p className="three-heads">Top Platforms</p>
                    <div className="d-flex align-items-center justify-content-around mt-2 p-2">
                      <div className="d-flex justify-content-around align-items-center">
                        <div>
                          <Doughnutchart
                            data={chartData as any}
                            options={chartOptions as any}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-3">
                        {(chartData as any)?.labels?.map(
                          (platform: string, index: number) => (
                            <div
                              key={index}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <i
                                className="pi pi-circle-on mr-2"
                                style={{
                                  color: (chartData as any)?.datasets[0]
                                    ?.backgroundColor[index],
                                }}
                              ></i>
                              <span className="text-chart-opt mr-3">
                                {platform}
                              </span>
                              <span className="text-chart-opt">
                                {(chartData as any)?.datasets[0]?.data[index]}%
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4">
                  <div className="top-three-divs p-3">
                    <p className="three-heads">Top Devices</p>
                    <div className="d-flex align-items-center justify-content-around mt-2 p-2">
                      <div className="d-flex justify-content-around align-items-center">
                        <div>
                          <Doughnutchart
                            data={devicechartData as any}
                            options={devicechartOptions as any}
                          />
                        </div>
                      </div>
                      <div className="d-flex flex-column gap-3">
                        {(devicechartData as any)?.labels?.map(
                          (device: string, index: number) => (
                            <div
                              key={index}
                              className="d-flex justify-content-between align-items-center"
                            >
                              <i
                                className="pi pi-circle-on mr-2"
                                style={{
                                  color: (devicechartData as any)?.datasets[0]
                                    ?.backgroundColor[index],
                                }}
                              ></i>
                              <span className="text-chart-opt mr-3">
                                {device}
                              </span>
                              <span className="text-chart-opt">
                                {(chartData as any)?.datasets[0]?.data[index]}%
                              </span>
                            </div>
                          )
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="d-flex gap-1  p-2 mb-4 col-lg-12">
                <div className="col-lg-8 p-2">
                  <div className="country-visitors d-flex justify-content-between p-3">
                    <div className="col-lg-8">
                      <p className="three-heads">Top Visitors</p>
                      <img style={{ height: "208px" }} src={countrypic} />
                    </div>
                    <div className="col-lg-4">
                      <div className="country-table p-3">
                        <div className="d-flex justify-content-between">
                          <p className="Country-visitors">Country</p>
                          <p className="Country-visitors">Visitors</p>
                        </div>
                        <hr style={{ margin: "8px 0px", color: "#F5F5F5", height: "2px" }} />
                        {topvisitors &&
                          topvisitors.map((visitor: any, index: any) => (
                            <React.Fragment key={index}>
                              <div className="d-flex justify-content-between">
                                <p className="Country-number">
                                  {visitor.country}
                                </p>
                                <p className="Country-number">
                                  {visitor.visitorCount}
                                </p>
                              </div>
                              <hr style={{ margin: "8px 0px", color: "#F5F5F5", height: "2px" }} />
                            </React.Fragment>
                          ))}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 p-2">
                  <div className="most-searched-words p-3">
                    <p className="three-heads">Most Searched Words</p>
                    <div className="word-div pl-3 pt-2 pr-3 pb-2 mb-2 d-flex justify-content-between">
                      <p className="word-count-text">Words</p>
                      <p className="word-count-text">Count</p>
                    </div>
                    {getMostSearchedData &&
                      getMostSearchedData.map((wordData, index) => (
                        <React.Fragment key={index}>
                          <div className="word-count-div d-flex justify-content-between">
                            <p className="word-count">{wordData._id}</p>
                            <p className="word-count">{wordData.Count}</p>
                          </div>
                          <hr style={{ margin: "8px 0px", color: "#ffffff", height: "2px" }} />
                        </React.Fragment>
                      ))}
                  </div>
                </div>
              </div>
              <div className="col-lg-12 d-flex justify-content-between p-2 mb-4">
                <div className="col-lg-4 p-2">
                  <div className="most-searched-words p-3">
                    <p className="three-heads">Top Referrers</p>
                    <div className="word-div pl-3 pt-2 pr-3 pb-2 mb-2 d-flex justify-content-between">
                      <p className="word-count-text">Referrers</p>
                      <div className="d-flex gap-3">
                        <p className="word-count-text">Visitors</p>
                        <p className="word-count-text">Views</p>
                      </div>
                    </div>
                    {topreferrers &&
                      topreferrers.map((referrer: any, index: any) => (
                        <React.Fragment key={index}>
                          <div className="word-count-div d-flex justify-content-between">
                            <p className="word-count">{referrer.referrer}</p>
                            <div className="d-flex gap-6 ">
                              <p className="word-count">
                                {referrer.uniqueVisitors}
                              </p>
                              <p className="word-count">{referrer.visits}</p>
                            </div>
                          </div>
                          <hr style={{ margin: "8px 0px", color: "#ffffff", height: "2px" }} />
                        </React.Fragment>
                      ))}
                  </div>
                </div>
                <div className="col-lg-8 p-2">
                  <div className="top-pages d-flex flex-column p-3">
                    <p className="three-heads">Top Pages</p>
                    <div className="col-lg-12 d-flex flex-column">
                      <div className="word-div pl-3 pt-2 pr-3 pb-2 mb-2 d-flex justify-content-between">
                        <p className="word-count-text">Pages</p>
                        <div className="d-flex col-lg-4 justify-content-between">
                          <p className="word-count-text">Entries</p>
                          <p className="word-count-text">Visitors</p>
                          <p className="word-count-text">Views</p>
                        </div>
                      </div>
                      {toppages &&
                        toppages.map((pageData: any, index: any) => (
                          <React.Fragment key={index}>
                            <div className="word-count-div d-flex justify-content-between">
                              <p className="word-count">{pageData.pagelink}</p>
                              <div className="d-flex col-lg-4 justify-content-between">
                                <p className="word-count">{pageData.entries}</p>
                                <p className="word-count">
                                  {pageData.uniqueVisitorsCount}
                                </p>
                                <p className="word-count">{pageData.totalVisits}</p>
                              </div>
                            </div>
                            <hr style={{ margin: "8px 0px", color: "#ffffff", height: "2px" }} />
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
};

export default Analytics;
