import { Skeleton } from "primereact/skeleton";
import React, { useState } from "react";
import "./aditionalInfo.component.css";
import { ProgressBar } from "primereact/progressbar";

type props = {
  description: string[];
  whyyeldam: string[];
  features: string[];
  hygiene: number;
  health:number;
  taste:number;
  costeffective:number;
  valuesrating:number;
  hospitality:number
};

const AditionalInfo: React.FC<props> = ({
  description,
  whyyeldam,
  features,
  hygiene,
  health,
  taste,
  costeffective,
  valuesrating,
  hospitality
}) => {
  const [lvisible, setLvisible] = useState(false);
  const convertToPercentage = (value: number) => {
    return (value / 10) * 100;
  };

  const determineColor = (value: number) => {
    if (value >= 7) {
      return "green"; 
    } else if (value >= 4 && value <= 6) {
      return "orange"; 
    } else {
      return "pink"; 
    }
  };

  return (
    <>
      <div className="main">
        <div className="row">
          <div className="col-lg-5">
            <div className="gap-2 about d-flex align-items-start">
              {features[0] === "None" ? (
                ""
              ) : (
                <>
                  <div className="serviceoptions mb-3">
                    {lvisible ? (
                      <>
                        <Skeleton width="10rem" className="mb-2"></Skeleton>
                        <Skeleton width="100%" className="mb-2"></Skeleton>
                      </>
                    ) : (
                      <>
                        <h6 className="mb-1">Facilities & Features</h6>
                        {features.map((tag, i) => (
                          <span
                            className="facilities-features"
                            key={"features" + i}
                          >
                            {i > 0 && ", "}
                            {tag === "Wifi" ? (
                              <span className="span-data">
                                {tag.trim()}
                              </span>
                            ) : (
                              ""
                            )}
                            {tag === "Parking" ? (
                              <span className="span-data">
                                {tag.trim()}
                              </span>
                            ) : (
                              ""
                            )}
                            {tag === "Valet Parking" ? (
                              <span className="span-data">
                                {tag.trim()}
                              </span>
                            ) : (
                              ""
                            )}
                            {tag === "Wallet Accepted" ? (
                              <span className="span-data">
                                {tag.trim()}
                              </span>
                            ) : (
                              ""
                            )}
                            {tag === "Cards Accepted" ? (
                              <span className="span-data">
                                {tag.trim()}
                              </span>
                            ) : (
                              ""
                            )}
                            {tag === "Air Conditioned" ? (
                              <span className="span-data">
                                {tag.trim()}
                              </span>
                            ) : (
                              ""
                            )}
                            {tag === "Lift" ? (
                              <span className="span-data">
                                {tag.trim()}
                              </span>
                            ) : (
                              ""
                            )}
                          </span>
                        ))}
                      </>
                    )}
                  </div>
                </>
              )}
            </div>
            <div className="about gap-2 d-flex align-items-start">
              <div className="about">
                <h6 className="mb-1">About The Restaurant</h6>
                <p className="span-data">{description}</p>
              </div>
            </div>
            <div className="Yeldam-ratings-div col-lg-12">
              <p className="yeldam-ratings-heading">Yeldam Ratings</p>
              <div className="d-flex justify-content-between">
                <div className="col-lg-5 d-flex flex-column gap-3 first-ratings-div">
                  <div>
                    <div className="Hygiene-head d-flex justify-content-between align-items-center mb-1">
                      <span className="ratinghead">Hygiene</span>
                      <span className="ratingnumber">{hygiene}/10</span>
                    </div>
                    <ProgressBar
                      value={convertToPercentage(hygiene)}
                      className={`p-progressbar ${determineColor(hygiene)}`}
                    ></ProgressBar>
                  </div>
                  <div>
                    <div className="Hygiene-head d-flex justify-content-between align-items-center mb-1">
                      <span className="ratinghead">Taste</span>
                      <span className="ratingnumber">{taste}/10</span>
                    </div>
                    <ProgressBar
                      value={convertToPercentage(taste)}
                      className={`p-progressbar ${determineColor(taste)}`}
                    ></ProgressBar>
                  </div>
                  <div>
                    <div className="Hygiene-head d-flex justify-content-between align-items-center mb-1">
                      <span className="ratinghead">Values</span>
                      <span className="ratingnumber">{valuesrating}/10</span>
                    </div>
                    <ProgressBar
                      value={convertToPercentage(valuesrating)}
                      className={`p-progressbar ${determineColor(valuesrating)}`}
                    ></ProgressBar>
                  </div>
                </div>
                <div className="col-lg-5 d-flex flex-column gap-3 first-ratings-div">
                  <div>
                    <div className="Hygiene-head d-flex justify-content-between align-items-center mb-1">
                      <span className="ratinghead">Health</span>
                      <span className="ratingnumber">{health}/10</span>
                    </div>
                    <ProgressBar
                      value={convertToPercentage(health)}
                      className={`p-progressbar ${determineColor(health)}`}
                    ></ProgressBar>
                  </div>
                  <div>
                    <div className="Hygiene-head d-flex justify-content-between align-items-center mb-1">
                      <span className="ratinghead">Cost Effective</span>
                      <span className="ratingnumber">{costeffective}/10</span>
                    </div>
                    <ProgressBar
                      value={convertToPercentage(costeffective)}
                      className={`p-progressbar ${determineColor(costeffective)}`}
                    ></ProgressBar>
                  </div>
                  <div>
                    <div className="Hygiene-head d-flex justify-content-between align-items-center mb-1">
                      <span className="ratinghead">Hospitality</span>
                      <span className="ratingnumber">{hospitality}/10</span>
                    </div>
                    <ProgressBar
                      value={convertToPercentage(hospitality)}
                      className={`p-progressbar ${determineColor(hospitality)}`}
                    ></ProgressBar>
                  </div>
                </div>
              </div>


            </div>
          </div>
          <div className="col-lg-5">
            <div className="content">
              <h6 className="mb-1">Why In Yeldam?</h6>
              <p>{whyyeldam.join(".")}</p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AditionalInfo;
