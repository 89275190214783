import HttpInstance from "./http.api";
type Agent={
    name:string,
    email:string,
    city:string,
    mobile:string
}
export const AddAgent=(AgentObj:Agent)=>{
   const respData= HttpInstance.post("/agent/createagent",AgentObj)
   return respData;
}

export const EditAgent=(id:string,EditObj:Agent)=>{
   const respData= HttpInstance.patch("/agent/updateagent?id="+id,EditObj)
   return respData;
}

export const GetAllAgents=()=>{
    const respData=HttpInstance.get("/agent/getallagents")
    return respData

}
export const AgentStatus = (id:string,status:boolean)=>{
    console.log("inside agent status",id,status);
    const respData=  HttpInstance.post("/agent/agentstatus?id="+id,{status:status})
    return respData
}