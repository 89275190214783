import React from "react";
import "./drop-file-input.css";
import { Toast } from "primereact/toast";
import { devsas } from "../../config/constants";

const DropFileInput: React.FC<any> = ({
  uploadedFiles,
  setUploadedFiles,
  resObj,
  setResObj,
  purpose,
}) => {
  const handleUploadFiles = (files: File[]) => {
    if (purpose === "ThumbnailImages") {
      setResObj({
        ...resObj,
        thumbnails: files,
      });
    }
    if (purpose === "RestaurantImages") {
      setResObj({
        ...resObj,
        images: files,
      });
    }
    if (purpose === "MenuImages") {
      setResObj({
        ...resObj,
        menuimages: files,
      });
    }
    if (purpose === "storeThumbnailImages") {
      setResObj({...resObj, thumbnails:files});
    }
    if (purpose === "Storeimages") {
      setResObj({...resObj, storeimages:files});
    }

    console.log('Uploaded Files:', files);
    setUploadedFiles(files);
  };


  
  const handleFileEvent = (e: any) => {
    const chosenFiles = Array.prototype.slice.call(e.target.files);
    handleUploadFiles(chosenFiles);
  };


  const fileRemove = (fileToRemove: any) => {
    const updatedFiles = uploadedFiles.filter(
      (item: any) => item !== fileToRemove
    );
    setUploadedFiles(updatedFiles);
  };
  function isNullOrEmpty(array:any) {
    return array === null || array === undefined || array.length === 0;
}


  const handleDeleteClick = (index: any, purpose: any) => {
    const updatedRestObj = { ...resObj };
    console.log("updatedRestObj",updatedRestObj);
    if (purpose === "ThumbnailImages") {
      const updatedThumbnails = [...resObj.thumbnails];
      updatedThumbnails.splice(index, 1);
      updatedRestObj.thumbnails = updatedThumbnails;
    } else if (purpose === "RestaurantImages") {
      const updatedImages = [...updatedRestObj.images];
      updatedImages.splice(index, 1);
      updatedRestObj.images = updatedImages;
    } else if (purpose === "MenuImages") {
      const updatedMenuImages = [...updatedRestObj.menuimages];
      updatedMenuImages.splice(index, 1);
      updatedRestObj.menuimages = updatedMenuImages;
    }
    setResObj(updatedRestObj);


  };

  return (
    <>
      <div className="upload-image-box m-2">
        <div className="container">
          <div className="d-flex justify-content-center align-items-center">
            <i
              className="pi pi-upload"
              style={{
                color: "#605E5C",
                padding: "10px",
                background: "#F9F9F9",
                borderRadius: "10px",
              }}
            ></i>
          </div>
          <div className="d-flex justify-content-center align-items-center">
            <label htmlFor={`upload-input-${purpose}`} className="upload-label">
              <div className="ym-image-placeholder d-flex gap-1" style={{ fontStyle: "16px" }}>
                Drag and drop file here or{" "}
                <span style={{ color: "#000" }}>choose file</span>
              </div>
              <div>
                <span className="d-flex justify-content-center" style={{ fontSize: "10px" }}>
                  Supports only JPG, PNG & JPEG - Max file size 1MBKB
                </span>
              </div>
            </label>
          </div>
        </div>

        <input
          id={`upload-input-${purpose}`}
          type="file"
          value=""
          multiple
          onChange={handleFileEvent}
          accept="image/png,image/jpg,image/jpeg"
        />
      </div>
       
      <div className="grid mt-2 ml-1 gap-3">

      {uploadedFiles.map((item: any, index: any) => {
        return (
          <div id="Imagecrd" style={{ position: "relative" }}>
            <img
              className="uploadimage"
              src={URL.createObjectURL(item)}
              alt=""
            />

            <i
              className="pi pi-trash icontrash"
              onClick={() => fileRemove(item)}
              style={{
                position: "absolute",
                top: "0",
                right: "0",
                margin: "8px",
              }}
            ></i>
          </div>
        );
      })}
</div>


 
    </>
  );
};

export default DropFileInput;
