import React, { FunctionComponent} from 'react'; // importing FunctionComponent
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primeicons/primeicons.css";
import "./approve-table.css";

type ApproveTableProps = {
    customers:any, 
    first:any, 
    sortField:any, 
    sortOrder:any,
    totalRecords:any, 
    onPage:any, 
    onSort:any, 
    onFilter:any, 
    filters:any,
    loading:any, 
    countryBodyTemplate:any,
    representativeBodyTemplate:any,
    actionBodyTemplate:any
  }

  const rowsNo = ()=>{
    var width = window.screen.width;
    
    if(width>=1920){
      return 13
    }else if(width>1420 && width<=1440 ){
      return 9
    }
    else{
      return 10
    }
  }



  // we can use children even though we haven't defined them in our CardProps
export const ApproveTable: FunctionComponent<ApproveTableProps> = ({ customers, first, sortField,sortOrder, totalRecords, onPage, onSort, onFilter,filters, loading,countryBodyTemplate,representativeBodyTemplate,actionBodyTemplate}) => 
  <DataTable 
  className='pending-table-heads'
  value={customers} 
  lazy
  filterDisplay="row" 
  responsiveLayout="scroll" 
  // dataKey="id"
  paginator 
  first={first} 
  rows={rowsNo()} 
  totalRecords={totalRecords} 
  onPage={onPage}
  onSort={onSort} 
  sortField={sortField} 
  sortOrder={sortOrder}
  onFilter={onFilter} 
  filters={filters} 
  loading={loading}
  >
  <Column field="name" header="Name" sortable filter filterPlaceholder="Search by Name" />
  <Column field="city" sortable header="City" filterField="city"  filter filterPlaceholder="Search by City" />
  <Column field="agentname" header="Agent" sortable filter filterPlaceholder="Search by Agent" />
  <Column body={actionBodyTemplate} header="Action" className='action_btn'  exportable={false} style={{ minWidth: '6rem' }}></Column>

</DataTable>