import React, { useRef, useState } from 'react';
import "./eventDetails.css";
import { Button } from 'primereact/button';
import { InputTextarea } from 'primereact/inputtextarea';
import { Approvefoodevent } from '../../../api/restaurant-details.api';
import { Rejectfoodevent } from '../../../api/restaurant-details.api';
import { PostComment } from "../../../api/adminComments.api";
import { Toast } from 'primereact/toast';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import Loader from '../../../components/ui/loader/loader.components';

type props = {
    eventtitle: string;
    eventstartdate: any;
    eventcity: string;
    eventdescription: string[];
    eventlink: string;
    agentname: string;
    id: string;
    closedialog: any;
    purpose: 'pending' | 'approved' | 'rejected';
}

const EventDetails: React.FC<props> = ({ eventtitle, eventstartdate, eventcity, eventdescription, eventlink, purpose, agentname, id, closedialog }) => {

    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    console.log(splitLocation);

    const navigate = useNavigate();

    const [status, setStatus] = useState<number>(0);
    const [currentIndex, setCurrentIndex] = useState<number>(0);
    const [lvisible, setLvisible] = useState(false);

    const handlereject = async () => {
        setCurrentIndex((prevIndex) => prevIndex + 1);
    }
    const handleApprove = () => {
        onSubmit(1);
    };

    const toast = useRef<Toast>(null);
    const show = (message: any, severity: any, summary: any) => {
        if (toast.current) {
            toast.current.show({
                severity: severity,
                summary: summary,
                detail: message,
            });
        } else {
            console.log("toast issue");
        }
    };
    const [commentObj, setCommentObj] = useState({
        comment: "",
        approvalstatus: "",
    });
    const onSubmit = async (approvestatus: any) => {

        if (approvestatus === 1) {
            console.log("statuss for approve", approvestatus);
            const respData = await Approvefoodevent(id as string);
            show(respData.data.message, "info", "Info");
            const { pathname } = location;
            const splitpath = pathname.split("/");
            const pathname_s = "/" + splitpath[1] + "/" + splitpath[2];
            navigate(pathname_s);
            closedialog();
        } else {
            console.log("statuss for reject", approvestatus);
            const respData = await Rejectfoodevent(id as string, {
                approvalstatus: 2,
                comment: commentObj.comment
            });
            console.log("API hitting for reject");
            show(respData.data.message, "info", "Info");
            const { pathname } = location;
            const splitpath = pathname.split("/");
            const pathname_s = "/" + splitpath[1] + "/" + splitpath[2];
            navigate(pathname_s);

            closedialog();
        }

    };


    let statusText = null;
    if (purpose === 'pending') {
        statusText = <span className='pending-text'>Pending</span>;
    } else if (purpose === 'approved') {
        statusText = <span className='approved-text'>Approved</span>;
    } else if (purpose === 'rejected') {
        statusText = <span className='rejected-text'>Rejected</span>;
    }

    const pendingfooterContent = (
        <div className="d-flex justify-content-end">
            <Button
                label="Reject"
                icon="pi pi-times-circle"
                className="reject_btn"

                onClick={() => {
                    handlereject();
                    setStatus(0);
                }}
            />
            <Button
                label="Approve"
                icon="pi pi-check-circle"
                className="p-button-rounded p-button-success change-btn-login"
                onClick={handleApprove}
                autoFocus
            />
        </div>
    );
    const approvefootercontent = (
        <div className='d-flex justify-content-end'>
            <Button
                label="Reject"
                icon="pi pi-times-circle"
                className="reject_btn"
                onClick={() => {
                    handlereject();
                    setStatus(0);
                }}
            />
        </div>

    );
    const rejectfootercontent = (
        <div className='d-flex justify-content-end'>
            <Button
                label="Approve"
                icon="pi pi-check-circle"
                className="p-button-rounded p-button-success change-btn-login"
                onClick={handleApprove}
                autoFocus
            />
        </div>
    );

    return (
        <main>
              {!lvisible ? '' : <Loader />}
        
        <>
            {currentIndex === 0 && (
                <div>
                    <div className='d-flex pt-2'>
                        {statusText}
                    </div>
                    <div className='event-title-name pt-2'>
                        <span>{eventtitle}</span>
                    </div>
                    <div className='d-flex gap-6 align-items-center pt-2'>
                        <div className='date-event d-flex align-items-center gap-2'>
                            <i className="pi pi-calendar" style={{ color: "#818181" }}></i> {" "}
                            <span className='date-text'>{eventstartdate}</span>
                        </div>
                        <div className='city-event d-flex align-items-center gap-2'>
                            <i className="pi pi-map-marker" style={{ color: "#818181" }}></i> {" "}
                            <span className='date-text'>{eventcity}</span>
                        </div>

                    </div>
                    <div className='pt-2'>
                        <span className='event-desc'>{eventdescription}</span>
                    </div>
                    <div className='pt-2 link-border'>
                        <p className="event-link gap-2 d-flex align-items-center">
                            <i
                                className="pi pi-globe"

                            ></i>
                            {eventlink}
                        </p>
                    </div>
                    <div className='agent-name'>
                        <p> Added by: <span>{agentname}</span></p>
                    </div>
                    <hr />
                    <div className='footer-div'>
                        {purpose === 'pending' && pendingfooterContent}
                        {purpose === 'approved' && approvefootercontent}
                        {purpose === "rejected" && rejectfootercontent}

                    </div>
                </div>
            )}
            {currentIndex === 1 && (
                <div>
                    <div className=" mb-4 d-flex">
                        &nbsp;
                        <span
                            className="back_rest_btn d-flex gap-2 align-items-center"
                            onClick={() => setCurrentIndex((prevIndex) => prevIndex - 1)}
                        >
                            <i className="pi pi-arrow-left"></i>
                            Rejection Details
                        </span>
                    </div>
                    <p className='reason-txt'>
                        Reason
                    </p>
                    <div className="row mb-3">
                        <div className="flex flex-column gap-2">
                            <InputTextarea
                                placeholder="Write here"
                                value={commentObj.comment}
                                onChange={(e: any) =>
                                    setCommentObj({ ...commentObj, comment: e.target.value })
                                }
                            />
                        </div>
                    </div>
                    <div className="d-flex justify-content-end">
                        <Button
                            label="Cancel"
                            onClick={() => setCurrentIndex((prevIndex) => prevIndex - 1)}
                            className="canceling_btn"
                        />
                        <Button
                            label="Reject"
                            className="p-button-rounded p-button-success change-btn-login"
                            onClick={() => {
                                onSubmit(2);
                                setStatus(0);
                                setCommentObj({ ...commentObj, approvalstatus: "2" });
                            }}
                            autoFocus
                        />
                    </div>

                </div>
            )}
        </>
        </main>
    );
}

export default EventDetails;
