import React from 'react'
import { Image } from "primereact/image";
import "./storeImages.component.css"
import { devsas } from "../../config/constants"

type props = {
  storeimages: string[];
}

const StoreImages: React.FC<props> = ({ storeimages }) => {
  return (
    <div>

      <div className="d-flex">
        {storeimages.map((item) => (
          <div className="store-img">
            {/*@ts-ignore   */}
            <Image src={item + "?" + devsas} alt="Image" preview />

          </div>
        ))}
      </div>
    </div>
  )
};

export default StoreImages