
import React, { useState, useEffect } from 'react';
import Navbar from '../../../components/ui/navbar/sidenav.components';
import { Button } from 'primereact/button';
import "./pendingEvents.page.css"
import { GetEventsTableData } from '../../../api/foodEvents.api';
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { PendingEventTable } from '../../../components/foodevents/pendingEvents/pendingEvents.component'; 
import { Dialog } from "primereact/dialog";
import EventDetails from '../FoodEventDetails/eventDetails';
import { GetEventbyID } from '../../../api/foodEvents.api';

interface PendingEventData {
    _id: string;
    eventtitle: string;
    eventstartdate: string;
    city: string;
    description: string[]; 
    referencelink: string;
    agentname: string;
  }

 const PendingEvents:React.FC<any> = () => {

    const initialPendingEvent: PendingEventData = {
        _id: '',
        eventtitle: '',
        eventstartdate: '',
        city: '',
        description: [],
        referencelink: '',
        agentname: '',
      };
    

    const navigate=useNavigate()
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [customers, setCustomers] = useState(undefined);
    const [pendingevents, setpendingevents] = useState<PendingEventData>(initialPendingEvent);
    const [isAdding, setIsadding] = useState(false);
    const [visible, setVisible] = useState<boolean>(false);

    const closeDialog = () => {
        setVisible (false);
    };

    var width = window.screen.width;
    var c_rows = 10;
    if(width>=1920){
        c_rows=15
    }
    else if(width>=1400 || width<=1600){
        c_rows=10
    }
    const [lazyParams, setLazyParams] = useState({
        approvalstatus:0,
        first: 0,
        rows: c_rows,
        page: 0,
        sortField: '',
        sortOrder: 0,
        filters: {
            'eventtitle': { value: '', matchMode: 'contains' },
            'city': { value: '', matchMode: 'contains' },
            'agentname': { value: '', matchMode: 'contains' },
        }
    });

    let loadLazyTimeout:any = 0;

    useEffect(() => {
        loadLazyData();
    },[lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        loadLazyTimeout = setTimeout(async() => {
            console.log(lazyParams);
            const respObj=await GetEventsTableData(lazyParams,0);
            setCustomers(respObj.data.foodevents);
            setTotalRecords(respObj.data.totalRecords);
            setLoading(false);
        }, 100);
    }

    const onPage = (event:any) => {
        setLazyParams(event);
    }

    const onSort = (event:any) => {
        setLazyParams(event);
    }

    const onFilter = (event:any) => {
        event['first'] = 0;
        setLazyParams(event);
    }


   

    const representativeBodyTemplate = (rowData:any) => {
        return (
            <React.Fragment>
                <img alt={rowData.representative.name} src={`https://www.primereact.org/images/avatar/${rowData.representative.image}`} width={32} style={{ verticalAlign: 'middle' }} />
                <span className="vertical-align-middle ml-2">{rowData.representative.name}</span>
            </React.Fragment>
        );
    }

    const countryBodyTemplate = (rowData:any) => {
        return (
            <React.Fragment>
                <img alt="flag" src="https://www.primereact.org/images/flag/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} width={30} />
                <span className="vertical-align-middle ml-2">{rowData.country.name}</span>
            </React.Fragment>
        );
    }
    const viewProduct = (product:any) => {
    //imitate delay of a backend call
    const loadLazyTimeout = setTimeout(async () => {
      setIsadding(true);
      setVisible(true)
      const eventData = await GetEventbyID(product._id as string);

      console.log("eventsData", eventData.data.data);

      setpendingevents(eventData.data.data);

      setIsadding(false);
    }, 100);
  };
      const actionBodyTemplate = (rowData:any) => {
        return (
            <React.Fragment>
        <Tooltip
            target=".p-dt-tooltip"
            // content="View"
            mouseTrack
            mouseTrackLeft={10}
            />
                <Button icon="pi pi-eye" label='View Details' className="p-dt-tooltip p-button-rounded mr-2 view-bt" onClick={() => viewProduct(rowData)} />
            </React.Fragment>
        );
    }
    return (
        <>
        <main>
          <div>
            <Navbar />
            <section className="page-content">
            <div className="ym-content-table">
                   <div className="page_title d-sm-flex">
                      <div>
                        <span className="event-span-head">Pending Food Events</span>
                      </div>
                    </div>
                    <hr className="mb-0"/>
                <div className="card pt-3 pb-3 pl-0 pr-0" style={{border:"none"}}>
                    <PendingEventTable 
                        customers={customers}  
                        first={lazyParams.first} 
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}  
                        totalRecords={totalRecords} 
                        onPage={onPage} 
                        onSort={onSort}
                        onFilter={onFilter} 
                        filters={lazyParams.filters}
                        loading={loading} 
                        countryBodyTemplate={countryBodyTemplate} 
                        representativeBodyTemplate={representativeBodyTemplate}
                        actionBodyTemplate={actionBodyTemplate} />
                </div>
              </div>
            </section>
          </div>
        </main>
        <div className='dialog-box'>
        <Dialog
          visible={visible}
          header="Food Event Details"
          style={{ width: "35vw",borderRadius:"12px" }}
          className="login-model"
          breakpoints={{
            "1920px": "50vw",
            "1420px": "50vw",
            "1120px": "50vw",
            "1090px": "40vw",
            "980px": "50vw",
            "840px": "60vw",
            "740px": "50vw",
            "641px": "70vw",
            "541px": "70vw",
            "490px": "80vw",
            "350px": "90vw",
          }}
          onHide={() => setVisible(false)}
        >
          <EventDetails
          eventtitle={pendingevents.eventtitle}
          eventstartdate = {pendingevents.eventstartdate}
          eventcity = {pendingevents.city}
          eventdescription = {pendingevents.description}
          eventlink = {pendingevents.referencelink}
          agentname ={pendingevents.agentname}
          id = {pendingevents._id}
          closedialog = {closeDialog}
          purpose='pending'
           />
        </Dialog>
      </div>
      </>
        
            
    );
}
export default PendingEvents;
        