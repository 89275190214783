import React from "react";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { InputText } from "primereact/inputtext";
import { Dropdown } from "primereact/dropdown";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { AddData } from "../../../api/settings.api";

type props = {
  show: boolean;
  setShow: Function;
};

const sectionOptions = [
  { value: "0", label: "Select a section" },
  { value: "1", label: "section 1" },
  { value: "2", label: "section 2" },
  { value: "3", label: "section 3" },
];

const AddBannersModal: React.FC<props> = ({ show, setShow }) => {
  const schema = Yup.object().shape({
    type: Yup.string().required("Type is a required field"),
    name: Yup.string().required("Name is required"),
    imageurl: Yup.string().required("Image is required"),
    section: Yup.string().required("Please select a section"),
  });

  const AddFooter = (
    <div>
      <Button
        label="Save"
        form="add"
        type="submit"
        className="p-button-rounded p-button-success save-btn-login"
      ></Button>
    </div>
  );

  const addBanners = async (values: any) => {
    const respData = await AddData(values);
    // toastShow(respData.data.message);
    setShow(false);
    refreshPage();
  };

  const refreshPage = () => {
    window.location.reload();
  };

  return (
    <>
      <Dialog
        header="Add Banners"
        visible={show}
        style={{ width: "30vw" }}
        onHide={() => setShow(false)}
        footer={AddFooter}
      >
        <Formik
          validationSchema={schema}
          initialValues={{ type: "", name: "", imageurl: "", section: "" }}
          onSubmit={(values) => {
            addBanners(values);
          }}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
          }) => (
            <div className="justify-content-center">
              <br></br>
              <form id="add" noValidate onSubmit={handleSubmit}>
                <div className="flex flex-column">
                  <label htmlFor="type">Type</label>
                  <InputText
                    id="type"
                    type="name"
                    placeholder="Enter Type..."
                    name="type"
                    value={values.type}
                    onChange={handleChange}
                  />
                  <p className="error">
                    {errors.type && touched.type && errors.type}
                  </p>
                </div>

                <div className="flex flex-column">
                  <label htmlFor="name">Name</label>
                  <InputText
                    id="name"
                    type="name"
                    placeholder="Enter Name ..."
                    name="name"
                    value={values.name}
                    onChange={handleChange}
                  />
                  <p className="error">
                    {errors.name && touched.name && errors.name}
                  </p>
                </div>

                <div className="flex flex-column">
                  <label htmlFor="images">Images</label>
                  <InputText
                    id="images"
                    placeholder="Enter URL..."
                    name="imageurl"
                    value={values.imageurl}
                    onChange={handleChange}
                  />
                  <p className="error">
                    {errors.imageurl && touched.imageurl && errors.imageurl}
                  </p>
                </div>

                <div className="flex flex-column">
                  <label htmlFor="section">Section</label>
                  <Dropdown
                    id="section"
                    placeholder="Select a section"
                    options={sectionOptions}
                    name="section"
                    value={values.section}
                    onChange={handleChange}
                  />
                  <p className="error">
                    {errors.section && touched.section && errors.section}
                  </p>
                </div>
              </form>
            </div>
          )}
        </Formik>
      </Dialog>
    </>
  );
};

export default AddBannersModal;
