import React, { useState } from "react";
import "./restaurantTimings.component.css";

type Props = {
  timing: {
    day: string;
    timings: { opens: string; closes: string; }[];
  }[];
  editable: Boolean;
};



const RestaurantTimings: React.FC<Props> = ({ timing, editable }) => {
  // const parsedTimings = timing.map((timingStr: any) => JSON.parse(timingStr));
  const [parsedTimings, setParsedTimings] = useState(timing.map((timingStr: any) => JSON.parse(timingStr))
  );

  const handleDeleteTiming = (dayIndex: number) => {
    const updatedTimings = parsedTimings.filter((_, index) => index !== dayIndex);
    setParsedTimings(updatedTimings);
  };


  return (
    <div className="main">
      <div className="timings-div  d-flex flex-column gap-2">
        {parsedTimings.map((dayData, dayindex) => (
          <>
            {Object.keys(dayData).map((day: string, dayIndex: number) => (
              <div key={dayindex} className="timings-card d-flex justify-content-between col-lg-12  p-2">

                {/* <div key={dayIndex} className=" d-flex  align-items-center"> */}

                <div className="col-lg-3 d-flex align-items-center"><p className="  day m-0">{day}</p></div>
                <div className="col-lg-6 d-flex flex-column justify-content-center">
                  {dayData[day].map((time: string, timeIndex: number) => (
                    <p className="time mb-1" key={timeIndex}>
                      {time}
                    </p>
                  ))}
                </div>


                {/* </div> */}
                <div className="col-lg-1 d-flex justify-content-end">
                  {editable &&
                    <button className="trash_btn" onClick={() => handleDeleteTiming(dayindex)} >
                      <i className="pi pi-trash icontrash">
                      </i>
                    </button>
                  }
                </div>
              </div>
            ))}

          </>

        ))}
      </div>
    </div>
  );
};

export default RestaurantTimings;
