
import React, { useState, useEffect } from 'react';
import Navbar from '../../../components/ui/navbar/sidenav.components';
import { Button } from 'primereact/button';
import "./approvedStores.page.css"
import { useNavigate } from 'react-router-dom';
import { Tooltip } from 'primereact/tooltip';
import { GetOrganicStoresTableData } from '../../../api/organicStores.api';
import { ApprovedStoreTable } from '../../../components/organicStores/ApprovedStoreTable/approvedStore-table.component';

export default function ApprovedStores() {
    const navigate=useNavigate()
    const [loading, setLoading] = useState(false);
    const [totalRecords, setTotalRecords] = useState(0);
    const [customers, setCustomers] = useState(undefined);
    var width = window.screen.width;
    var c_rows = 10;
    if(width>=1920){
        c_rows=15
    }
    else if(width>=1400 || width<=1600){
        c_rows=10
    }
    const [lazyParams, setLazyParams] = useState({
        approvalstatus:1,
        first: 0,
        rows: c_rows,
        page: 0,
        sortField: '',
        sortOrder: 0,
        filters: {
            'storename': { value: '', matchMode: 'contains' },
            'city': { value: '', matchMode: 'contains' },
            'agentname': { value: '', matchMode: 'contains' },
        }
    });

    let loadLazyTimeout:any = 0;

    useEffect(() => {
        loadLazyData();
    },[lazyParams]) // eslint-disable-line react-hooks/exhaustive-deps

    const loadLazyData = () => {
        setLoading(true);

        if (loadLazyTimeout) {
            clearTimeout(loadLazyTimeout);
        }

        loadLazyTimeout = setTimeout(async() => {
            console.log(lazyParams);
            const respObj=await GetOrganicStoresTableData(lazyParams,1);
            console.log("Inside pending",respObj.data.organicstores);
            setCustomers(respObj.data.organicstores);
            setTotalRecords(respObj.data.totalRecords);
            setLoading(false);
        }, 100);
    }

    const onPage = (event:any) => {
        setLazyParams(event);
    }

    const onSort = (event:any) => {
        setLazyParams(event);
    }

    const onFilter = (event:any) => {
        event['first'] = 0;
        setLazyParams(event);
    }


   

    const representativeBodyTemplate = (rowData:any) => {
        return (
            <React.Fragment>
                <img alt={rowData.representative.name} src={`https://www.primereact.org/images/avatar/${rowData.representative.image}`} width={32} style={{ verticalAlign: 'middle' }} />
                <span className="vertical-align-middle ml-2">{rowData.representative.name}</span>
            </React.Fragment>
        );
    }

    const countryBodyTemplate = (rowData:any) => {
        return (
            <React.Fragment>
                <img alt="flag" src="https://www.primereact.org/images/flag/flag_placeholder.png" className={`flag flag-${rowData.country.code}`} width={30} />
                <span className="vertical-align-middle ml-2">{rowData.country.name}</span>
            </React.Fragment>
        );
    }
    const viewProduct = (product:any) => {
        // setDeleteProductDialog(true);
        navigate(`/organicstores/approvedstores/details/${product._id}`)
        // window.open(`/organicstores/approvedstores/details/${product._id}`);
    }
      const actionBodyTemplate = (rowData:any) => {
        return (
            <React.Fragment>
        <Tooltip
            target=".p-dt-tooltip"
            // content="View"
            mouseTrack
            mouseTrackLeft={10}
            />
                <Button icon="pi pi-eye" label='View Details' className="p-dt-tooltip p-button-rounded mr-2 view-bt" onClick={() => viewProduct(rowData)} />
            </React.Fragment>
        );
    }
    return (
        <>
        <main>
          <div>
            <Navbar />
            <section className="page-content">
            <div className="ym-content-table">
                   <div className="page_title d-sm-flex">
                      <div>
                        <span className="event-span-head">Approved Organic Stores</span>
                      </div>
                    </div>
                    <hr className="mb-0"/>
                <div className="card pt-3 pb-3 pl-0 pr-0" style={{border:"none"}}>
                    <ApprovedStoreTable 
                        customers={customers}  
                        first={lazyParams.first} 
                        sortField={lazyParams.sortField}
                        sortOrder={lazyParams.sortOrder}  
                        totalRecords={totalRecords} 
                        onPage={onPage} 
                        onSort={onSort}
                        onFilter={onFilter} 
                        filters={lazyParams.filters}
                        loading={loading} 
                        countryBodyTemplate={countryBodyTemplate} 
                        representativeBodyTemplate={representativeBodyTemplate}
                        actionBodyTemplate={actionBodyTemplate} />
                </div>
              </div>
            </section>
          </div>
        </main>
      </>
        
            
    );
}
        