import React from 'react'
import {devsas}  from "../../config/constants"
import { Image } from "primereact/image";

type props = {
   menuimages: string[];
}
const Restaurantmenu: React.FC<props> = ({
   menuimages
}) => {
  return (
    <div>
      <div className="d-flex">
      {menuimages.map((item) => (
                   <div className="rest-img">
                   {/*@ts-ignore   */}
                   <Image src={item+"?"+devsas} key={item} preview />
               </div>
                ))}
      </div>
    </div>
  )
}

export default Restaurantmenu
