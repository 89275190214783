import React, { FunctionComponent } from 'react'; // importing FunctionComponent
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import "primeicons/primeicons.css";
import "../agents/agent-table.css"

type AgentsTableProps = {
    customers:any, 
    first:any, 
    sortField:any, 
    sortOrder:any,
    // totalRecords:any, 
    onPage:any, 
    onSort:any, 
    onFilter:any, 
    filters:any,
    loading:any, 
    // countryBodyTemplate:any,
    // representativeBodyTemplate:any,
    actionBodyTemplate:any,
    statusBodyTemplate:any
  }
  var width = window.screen.width;
    var c_rows = 10;
    if(width>=1920){
        c_rows=15
    }
    else if(width>=1400 || width<=1600){
        c_rows=10
    }



  // we can use children even though we haven't defined them in our CardProps
export const AgentsTable: FunctionComponent<AgentsTableProps> = ({ customers, first, sortField,sortOrder,/* totalRecords*/ onPage, onSort, onFilter,filters, loading/*,countryBodyTemplate,representativeBodyTemplate,*/,actionBodyTemplate,statusBodyTemplate}) => 
  <DataTable 
  value={customers} 
  lazy
  filterDisplay="row" 
  responsiveLayout="scroll" 
  // dataKey="id"
  // paginator 
//   rowsPerPageOptions={[10,25,50]}
  first={first} 
  rows={c_rows} 
  // totalRecords={totalRecords} 
  onPage={onPage}
  onSort={onSort} 
  sortField={sortField} 
  sortOrder={sortOrder}
  onFilter={onFilter} 
  filters={filters} 
  loading={loading}
  selectionMode="single"
  >

  <Column field="name" header="Full Name" sortable filter filterPlaceholder="Search by Name" />
  <Column field="email" sortable header="Email"  filter filterPlaceholder="Search by Email" />
  <Column field="mobile" sortable filter header="Phone Number" filterPlaceholder="Search by PhoneNo" />
  <Column field="city" sortable header="City"  filter filterPlaceholder="Search by City" />
  <Column header="Status" body={statusBodyTemplate} exportable={false} style={{ minWidth: '3rem' }}></Column>
  <Column header="Action" body={actionBodyTemplate} exportable={false} style={{ minWidth: '3rem' }}></Column>

</DataTable>

