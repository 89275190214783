import React, { useEffect, useRef, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import Navbar from "../../../components/ui/navbar/sidenav.components";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { ApproveOrganicStore } from "../../../api/restaurant-details.api";
import { RejectOrganicStore } from "../../../api/restaurant-details.api";
import { PostComment } from "../../../api/adminComments.api";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Loader from "../../../components/ui/loader/loader.components";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { Image } from "primereact/image";
import "./storeDetails.page.css";
import { devsas } from "../../../config/constants"
import { GetStorebyID } from "../../../api/organicStores.api";
import ServiceOptions from "../../../components/StoreServiceoptions/serviceOptions.component";
import StoreTimings from "../../../components/StoreTimings/storeTimings.component";
import StoreImages from "../../../components/StoreImages/storeImages.component";
import EditStore from "../edit-organicstore/EditStore";



const StoreDetails: React.FC<any> = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log(splitLocation);
  const { id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<number>(0);
  const [visible, setVisible] = useState(false);
  const [lvisible, setLvisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [isAdding, setIsadding] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const storeStatus = async () => {

    setVisible(false);
  };
  const [commentObj, setCommentObj] = useState({
    comment: "",
    approvalstatus: "",
  });

  const loadLazyData = () => {
    //imitate delay of a backend call
    const loadLazyTimeout = setTimeout(async () => {
      setIsadding(true);
      const storeData = await GetStorebyID(id as string);

      console.log("storeData", storeData.data.data);

      setStoretObj(storeData.data.data);
      setLvisible(false);
      setIsadding(false);
    }, 100);
  };
  useEffect(() => {
    setIsadding(true);
    loadLazyData();
    setLvisible(true);
  }, []);

  const [storeObj, setStoretObj] = useState({
    _id: "",
    address: "",
    storename: "",
    phoneno: "",
    email: "",
    agentname: "",
    approvalstatus: "",
    city: "",
    country: "",
    createdAt: "",
    dateadded: "",
    district: "",
    location: { coordinates: [0.0, 0.0] },
    pincode: "",
    serviceoptions: "",
    servicetype: [],
    state: "",
    storeimages: [],
    storesize: [],
    storetype: [],
    thumbnails: [],
    timings: [],
    updatedAt: "",
    website: "",
    __v: "",
  });

  console.log("storeeeee", storeObj);

  const toast = useRef<Toast>(null);
  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };

  const onSubmit = async () => {
    if (status === 1) {
      const respData = await ApproveOrganicStore(id as string);
      show(respData.data.message, "info", "Info");
    } else {

      const respData = await RejectOrganicStore(id as string, {
        approvalstatus: 2,
        comment: commentObj.comment
      });
      console.log("API hitting for reject");
      show(respData.data.message, "info", "Info");
    }

    const { pathname } = location;
    const splitpath = pathname.split("/");
    const pathname_s = "/" + splitpath[1] + "/" + splitpath[2];
    navigate(pathname_s);
  };


  const footerContent = (
    <div className="d-flex justify-content-center">
      <Button
        label="No"
        onClick={() => {
          setVisible(false);
        }}
        className="canceling_btn"
      />
      <Button
        label="Yes"
        className="p-button-rounded p-button-success change-btn-login"
        onClick={() => {
          onSubmit();
          storeStatus();
        }}
        autoFocus
      />
    </div>
  );

  return (
    <main>
      {!lvisible ? '' : <Loader />}
      <div>
        <Navbar />
        <section className="page-content">
          <div className="ym-content-table mt-0">
            <div className="page_title d-flex">
              <div className="p-2 d-flex">
                &nbsp;
                <span
                  className="back_rest_btn d-flex gap-2 align-items-center"
                  onClick={() => {
                    const currentPath = window.location.pathname;
                    if (currentPath.includes("/pendingstores")) {
                      navigate(`/organicstores/pendingstores`);
                    } else if (currentPath.includes("/approvedstores")) {
                      navigate(`/organicstores/approvedstores`);
                    } else if (currentPath.includes("/rejected")) {
                      navigate(`/organicstores/rejectedstores`);
                    }
                  }}
                >
                  <i className="pi pi-arrow-left"></i>
                  Organic Store Details
                </span>
              </div>

              {splitLocation[2] === "pendingstores" ? (
                <div className="ml-auto ">
                  <Button
                    label="Edit"
                    icon="pi pi-user-edit"
                    onClick={() => {
                      setEditVisible(true);

                    }}
                    className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                  />

                  <Button
                    label="Reject"
                    icon="pi pi-times-circle"
                    onClick={() => {
                      setVisible(true);
                      setStatus(0);
                      setCommentObj({ ...commentObj, approvalstatus: "0" });
                    }}
                    className="p-button-outlined p-button-rounded p-button-danger reject_btn"
                  />
                  <Button
                    label="Approve"
                    icon="pi pi-check-circle"
                    className="p-button-rounded p-button-success change-btn-login"
                    onClick={() => {
                      setVisible(true);
                      setStatus(1);

                    }}
                    autoFocus
                  />
                </div>
              ) : splitLocation[2] === "approvedstores" ? (
                <div className="ml-auto ">
                  <Button
                    label="Edit"
                    icon="pi pi-user-edit"
                    onClick={() => {
                      setEditVisible(true);

                    }}
                    className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                  />

                  <Button
                    label="Reject"
                    icon="pi pi-times-circle"
                    onClick={() => {
                      setVisible(true);
                      setStatus(0);
                      setCommentObj({ ...commentObj, approvalstatus: "0" });
                    }}
                    className="p-button-outlined p-button-rounded p-button-danger reject_btn"
                  />
                </div>
              ) : (
                <div className="ml-auto ">
                  <Button
                    label="Edit"
                    icon="pi pi-user-edit"
                    onClick={() => {
                      setEditVisible(true);

                    }}
                    className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                  />
                  <Button
                    label="Approve"
                    icon="pi pi-check-circle"
                    className="p-button-rounded p-button-success change-btn-login"
                    onClick={() => {
                      setVisible(true);
                      setStatus(1);
                      setCommentObj({ ...commentObj, approvalstatus: "1" });
                    }}
                    autoFocus
                  />
                </div>
              )}
            </div>
            
            <hr/>
            <div className="ym-content-table mb-4">
              <div className=" gap-3 d-flex align-items-start">
                <div
                  className="row mb-1 col-lg-3 view-gallery-cols"
                  style={{ height: "180px" }}
                >
                  <div className="col-5 pt-0 pl-0">
                    {/* @ts-ignore       */}
                    <Image src={storeObj.thumbnails[0] + "?" + devsas} alt="Image" preview />
                  </div>
                </div>

                <div className="row mb-1 col-lg-9">
                  <div className="col d-flex align-items-left mb-4 justify-content-center flex-column ml-1 gap-3 rest-details">
                    <div className="d-flex gap-3" >
                      <div className="page-title col-lg-6">
                        {!lvisible ? (
                          <h1 className="ym-portal-heading">
                            {storeObj.storename}
                          </h1>
                        ) : (
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                        )}
                      </div>

                      <div className="agent-disp col-lg-6">
                        {!lvisible ? (
                          <div className="d-flex agent-added justify-content-center align-items-center">
                            <label>Added By:</label>&nbsp;
                            <p className="mb-0">{storeObj.agentname}</p>
                          </div>
                        ) : (
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                        )}
                      </div>
                    </div>

                    <div className="address-latlong gap-3 d-flex">
                      <div className="address-div col-lg-6">
                        {!lvisible ? (
                          <div>
                            <p className="address-heading gap-2 d-flex align-items-center">
                              <i
                                className="pi pi-map-marker"
                                style={{ fontSize: "12px" }}
                              ></i>
                              Address
                            </p>
                            <p className="area-data mb-0 ml-3">
                              {storeObj.address +
                                "," +
                                storeObj.city +
                                "," +
                                " " +
                                storeObj.state +
                                "-" +
                                storeObj.pincode +
                                "."}
                            </p>
                          </div>
                        ) : (
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                        )}
                      </div>
                      <div className="lat-long-div">
                        {!lvisible ? (
                          <div>
                            <p className="address-heading gap-2 d-flex align-items-center">
                              <i
                                className="pi pi-map"
                                style={{ fontSize: "12px" }}
                              ></i>
                              Latitude & Longitude
                            </p>
                            <p className="area-data mb-0 ml-4">
                              {storeObj.location.coordinates[0] +
                                " & " +
                                storeObj.location.coordinates[1]}
                            </p>
                          </div>
                        ) : (
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                        )}
                      </div>
                    </div>
                    <div className="phone-email-web d-flex gap-3">
                      <div className="phone-email d-flex col-lg-6">
                        <div className="phone-num col-lg-6">
                          {!lvisible ? (
                            <div>
                              <p className="address-heading gap-2 d-flex align-items-center">
                                <i
                                  className="pi pi-phone"
                                  style={{ fontSize: "12px" }}
                                ></i>
                                Phone
                              </p>
                              <p className="area-data mb-0 ml-3">
                                <a href={`tel:${storeObj.phoneno}`}>
                                  {storeObj.phoneno}
                                </a>
                              </p>
                            </div>
                          ) : (
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                          )}
                        </div>
                        <div className="email-st">
                          {!lvisible ? (
                            <div>
                              <p className="address-heading gap-2 d-flex align-items-center">
                                <i
                                  className="pi pi-envelope"
                                  style={{ fontSize: "12px" }}
                                ></i>
                                Email
                              </p>
                              <p className="area-data mb-0">
                                <a href={`mailto:${storeObj.email}`} className="area-data mb-0">
                                  {storeObj.email}
                                </a>
                              </p>
                            </div>
                          ) : (
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                          )}
                        </div>
                      </div>
                      <div className="website">
                        {!lvisible ? (
                          <div>
                            <p className="address-heading gap-2 d-flex align-items-center">
                              <i
                                className="pi pi-globe"
                                style={{ fontSize: "12px" }}
                              ></i>
                              Website
                            </p>
                            <p className="area-data mb-0 ">
                              {" "}
                              {storeObj.website}
                            </p>
                          </div>
                        ) : (
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <TabView>
              <TabPanel header="Service Options">
                <ServiceOptions
                  storetype={storeObj.storetype}
                  servicetype={storeObj.servicetype}
                  serviceoptions={storeObj.serviceoptions}
                  storesize={storeObj.storesize}
                />
              </TabPanel>
              <TabPanel header="Timings">
                <StoreTimings timings={storeObj.timings} />
              </TabPanel>
              <TabPanel header="Images">
                <StoreImages
                  storeimages={storeObj.storeimages}
                />
              </TabPanel>
            </TabView>
          </div>
          <div className="approve-reject-dialog">
            <Dialog

              visible={visible}
              style={{ width: "30vw" }}
              onHide={() => {
                setVisible(false);
              }}
              footer={footerContent}
            >
              <p className="status-giving">
                Are you sure you want to {status === 0 ? "reject" : "approve"}{" "} this {" "}
                {storeObj.storename} ?
              </p>
              <p>Reason:</p>
              <div className="row">
                <div className="flex flex-column gap-2">
                  <InputTextarea
                    placeholder="Add Reason..."
                    value={commentObj.comment}
                    onChange={(e: any) =>
                      setCommentObj({ ...commentObj, comment: e.target.value })
                    }
                  />
                </div>
              </div>
            </Dialog>
            <Dialog
              header="Edit OrganicStore Details"
              visible={editVisible}
              style={{ width: "45vw", height: "100vh" }}
              onHide={() => {
                setEditVisible(false);
              }}

            >
              <EditStore  storedataobj={storeObj} setEditVisible={setEditVisible}/>
            </Dialog>
          </div>
        </section>
      </div>
    </main>
  );
}

export default StoreDetails;
