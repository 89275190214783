import React from 'react';

interface props {
    clinicname: string;
    phone: string;
    email: string;
    address: string;
    locality: string;
    city: string;
    state: string;
    pincode: string;
    latitude: number;
    longitude: number;
    clinicwebsite: string;
    clinicinstagram: string;
    clinictwitter: string;
    clinicyoutube: string;
    clinicfacebook: string;
}

const ClinicInfo: React.FC<props> = ({
    clinicname,
    phone,
    email,
    address,
    locality,
    city,
    state,
    pincode,
    latitude,
    longitude,
    clinicwebsite,
    clinicfacebook,
    clinicinstagram,
    clinictwitter,
    clinicyoutube
 }) => {
    return (
        <>
            <div>
                <div className='d-flex'>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Clinic Name</h6>
                        <p className='sub-detail-data'>{clinicname}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Phone</h6>
                        <p className='sub-detail-data'>{phone}</p>
                    </div>
                    <div className='col-3'>
                        <h6 className="mb-1 sub-detail-head">Email</h6>
                        <p className='sub-detail-data'>{email}</p>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Clinic Address</h6>
                        <p className='sub-detail-data'>
                            {
                                address  +
                                ", " +
                                locality  +
                                ", " +
                                city  +
                                ", " +
                                state  +
                                " - " + 
                                pincode +
                                "." 
                            }
                        </p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Latitude & Longitude</h6>
                        <p className='sub-detail-data'>{latitude + " & " + longitude}</p>
                    </div>
                    <div className='col-3'>
                        <h6 className="mb-1 sub-detail-head">Website</h6>
                        <p className='sub-detail-data'>{clinicwebsite}</p>
                    </div>
                </div>
                <div className='d-flex'>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Instagram</h6>
                        <p className='sub-detail-data'>{clinicinstagram}</p>
                    </div>
                    <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Twitter</h6>
                        <p className='sub-detail-data'>{clinictwitter}</p>
                    </div>
                    <div className='col-3'>
                        <h6 className="mb-1 sub-detail-head">Youtube</h6>
                        <p className='sub-detail-data'>{clinicyoutube}</p>
                    </div>
                </div>
                <div className='d-flex'>
                <div className='col-4'>
                        <h6 className="mb-1 sub-detail-head">Facebook</h6>
                        <p className='sub-detail-data'>{clinicfacebook}</p>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ClinicInfo;
