import React, { useEffect, useRef, useState } from 'react';
import "./dietitianDetails.css";
import { TabView, TabPanel } from "primereact/tabview";
import { Button } from "primereact/button";
import Navbar from '../../../components/ui/navbar/sidenav.components';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { ApproveDietitian, GetDietitiandetailsbyID, RejectDietitian } from '../../../api/dietitians.api';
import { Skeleton } from 'primereact/skeleton';
import { Image } from "primereact/image";
import { devsas } from "../../../config/constants"
import DietitianInfo from '../../../components/Dietitians/DietitianInfo/dietitianInfo';
import EducationalInfo from '../../../components/Dietitians/EducationalInfo/educationalInfo';
import ClinicInfo from '../../../components/Dietitians/ClinicInfo/clinicInfo';
import DietitianTimings from '../../../components/Dietitians/DietitianTimings/dietitianTimings';
import ClinicAndDietitianImages from '../../../components/Dietitians/ClinicAndDietitianImages/clinicAndDietitianImages';
import { Dialog } from 'primereact/dialog';
import { InputTextarea } from 'primereact/inputtextarea';
import { Toast } from 'primereact/toast';

const DietitianDetails = () => {
    const location = useLocation();
    const { pathname } = location;
    const splitLocation = pathname.split("/");
    console.log(splitLocation);
    const { id } = useParams();
    const navigate = useNavigate();
    const [status, setStatus] = useState<number>(0);
    const [visible, setVisible] = useState(false);
    const [lvisible, setLvisible] = useState(false);
    const [editVisible, setEditVisible] = useState(false);
    const [isAdding, setIsadding] = useState(false);
    const [commentObj, setCommentObj] = useState({
        comment: "",
        approvalstatus: "",
    });
    const DietitianStatus = async () => {

        setVisible(false);
    };

    const loadlazydata = () => {
        const loadLazyTimeout = setTimeout(async () => {
            setIsadding(true);
            const storeData = await GetDietitiandetailsbyID(id as string);

            console.log("storeData", storeData.data.data);

            setDietObj(storeData.data.data);
            setLvisible(false);
            setIsadding(false);
        }, 100);
    };
    useEffect(() => {
        setIsadding(true);
        loadlazydata();
        setLvisible(true);
    }, []);

    const [dietObj, setDietObj] = useState({
        about: '',
        agentname: "",
        approvalstatus: "",
        awards: [],
        certification: "",
        certificationimages: [],
        city: "",
        clinicaddress: "",
        clinicfacebookurl: "",
        clinicimages: [],
        clinicinstaurl: "",
        clinicname: "",
        clinictwitterurl: "",
        clinicwebsite: "",
        clinicyoutubeurl: "",
        country: "",
        collage: "",
        createdAt: "",
        dateadded: "",
        dietitianfacebookurl: "",
        dietitianimages: [],
        dietitianinstaurl: "",
        dietitianname: "",
        dietitiantitle: "",
        dietitiantwitterurl: "",
        dietitianwebsite: "",
        district: "",
        degree: "",
        email: "",
        experience: "",
        fees: "",
        gender: "",
        languages: [],
        locality: "",
        location: { coordinates: [0.0, 0.0] },
        mode: [],
        membership: [],
        phoneno: "",
        pincode: "",
        registereddietitian: "",
        services: [],
        speciality: [],
        timings: [],
        yearofcompletion: "",
        state: "",
        _id: ""
    });

    const toast = useRef<Toast>(null);
    const show = (message: any, severity: any, summary: any) => {
        if (toast.current) {
            toast.current.show({
                severity: severity,
                summary: summary,
                detail: message,
            });
        } else {
            console.log("toast issue");
        }
    };

    const onSubmit = async () => {

        if (status === 1) {
            const respData = await ApproveDietitian(id as string);
            show(respData.data.message, "info", "Info");
            console.log("status1");
        } else {
            console.log("status2");
            const respData = await RejectDietitian(id as string, {
                approvalstatus: 2,
                comment: commentObj.comment

            });
            console.log("API hitting for reject");
            show(respData.data.message, "info", "Info");
        }

        const { pathname } = location;
        const splitpath = pathname.split("/");
        const pathname_s = "/" + splitpath[1] + "/" + splitpath[2];
        navigate(pathname_s);
    };

    console.log("diettttt", dietObj);
    const footerContent = (
        <div className="d-flex justify-content-center">
            <Button
                label="No"
                onClick={() => {
                    setVisible(false);
                }}
                className="canceling_btn"
            />
            <Button
                label="Yes"
                className="p-button-rounded p-button-success change-btn-login"
                onClick={() => {
                    onSubmit();
                    DietitianStatus();
                }}
                autoFocus
            />
        </div>
    );


    return (
        <main>
            <div>
                <Navbar />
                <section className="page-content">
                    <div className="ym-content-table mt-0">
                        <div className="page_title d-flex">
                            <div className="p-2 d-flex">
                                &nbsp;
                                <span
                                    className="back_rest_btn d-flex gap-2 align-items-center"
                                    onClick={() => {
                                        const currentPath = window.location.pathname;
                                        if (currentPath.includes("/pending")) {
                                            navigate(`/dietitian/pendingdietitian`);
                                        } else if (currentPath.includes("/approved")) {
                                            navigate(`/dietitian/approveddietitian`);
                                        } else if (currentPath.includes("/rejected")) {
                                            navigate(`/dietitian/rejecteddietitian`);
                                        }
                                    }}
                                >
                                    <i className="pi pi-arrow-left"></i>
                                    Dietitian Details
                                </span>
                            </div>

                            {splitLocation[2] === "pendingdietitian" ? (
                                <div className="ml-auto ">
                                    <Button
                                        label="Edit"
                                        icon="pi pi-user-edit"
                                        onClick={() => {
                                            setEditVisible(true);

                                        }}
                                        className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                                    />

                                    <Button
                                        label="Reject"
                                        icon="pi pi-times-circle"
                                        onClick={() => {
                                            setVisible(true);
                                            setStatus(0);
                                            setCommentObj({ ...commentObj, approvalstatus: "0" });
                                        }}
                                        className="p-button-outlined p-button-rounded p-button-danger reject_btn"
                                    />
                                    <Button
                                        label="Approve"
                                        icon="pi pi-check-circle"
                                        className="p-button-rounded p-button-success change-btn-login"
                                        onClick={() => {
                                            setVisible(true);
                                            setStatus(1);

                                        }}
                                        autoFocus
                                    />
                                </div>
                            ) : splitLocation[2] === "approveddietitian" ? (
                                <div className="ml-auto ">
                                    <Button
                                        label="Edit"
                                        icon="pi pi-user-edit"
                                        onClick={() => {
                                            setEditVisible(true);

                                        }}
                                        className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                                    />

                                    <Button
                                        label="Reject"
                                        icon="pi pi-times-circle"
                                        onClick={() => {
                                            setVisible(true);
                                            setStatus(0);
                                            setCommentObj({ ...commentObj, approvalstatus: "0" });
                                        }}
                                        className="p-button-outlined p-button-rounded p-button-danger reject_btn"
                                    />
                                </div>
                            ) : (
                                <div className="ml-auto ">
                                    <Button
                                        label="Edit"
                                        icon="pi pi-user-edit"
                                        onClick={() => {
                                            setEditVisible(true);

                                        }}
                                        className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                                    />
                                    <Button
                                        label="Approve"
                                        icon="pi pi-check-circle"
                                        className="p-button-rounded p-button-success change-btn-login"
                                        onClick={() => {
                                            setVisible(true);
                                            setStatus(1);
                                            setCommentObj({ ...commentObj, approvalstatus: "1" });
                                        }}
                                        autoFocus
                                    />
                                </div>
                            )}
                        </div>
                        <hr />
                        <div className="ym-content-table mb-4">
                            <div className=" gap-3 d-flex align-items-start">
                                <div
                                    className="row mb-1 col-2 view-gallery-cols"
                                    style={{ height: "180px" }}
                                >
                                    <div className="col-5 pt-0 pl-0">
                                        {/* @ts-ignore       */}
                                        <Image className='main-details-profile' src={dietObj.dietitianimages[0] + "?" + devsas} alt="Image" preview />
                                    </div>
                                </div>

                                <div className="row mb-1 col-lg-9">
                                    <div className="col d-flex align-items-left mb-4 justify-content-center flex-column ml-1 gap-4 rest-details">
                                        <div className="d-flex gap-3" >
                                            <div className="page-title col-lg-6">
                                                {!lvisible ? (
                                                    <h1 className="ym-portal-heading">
                                                        {dietObj.dietitiantitle} {dietObj.dietitianname}
                                                    </h1>
                                                ) : (
                                                    <Skeleton width="10rem" className="mb-2"></Skeleton>
                                                )}
                                            </div>


                                        </div>

                                        <div className="d-flex">
                                            <div className="address-div col-lg-3">
                                                {!lvisible ? (
                                                    <div>
                                                        <p className="address-heading gap-2 d-flex align-items-center">
                                                            Gender
                                                        </p>
                                                        <p className="area-data mb-0">
                                                            {dietObj.gender}
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                )}
                                            </div>

                                            <div className="address-div col-lg-3">
                                                {!lvisible ? (
                                                    <div>
                                                        <p className="address-heading gap-2 d-flex align-items-center">
                                                            Experience
                                                        </p>
                                                        <p className="area-data mb-0">
                                                            {dietObj.experience} Years
                                                        </p>
                                                    </div>
                                                ) : (
                                                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                )}
                                            </div>

                                            <div className="address-div col-lg-3">
                                                {!lvisible ? (
                                                    <div>
                                                        <p className="address-heading gap-2 d-flex align-items-center">
                                                            Specialty
                                                        </p>
                                                        <p className="area-data mb-0">
                                                            {dietObj.speciality.join('/')}
                                                        </p>

                                                    </div>
                                                ) : (
                                                    <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                )}
                                            </div>

                                        </div>

                                        <div className=" d-flex gap-3">
                                            <div className="phone-email d-flex col-lg-6">
                                                <div className="phone-num col-lg-6">
                                                    {!lvisible ? (
                                                        <div>
                                                            <p className="address-heading gap-2 d-flex align-items-center">
                                                                Mode
                                                            </p>
                                                            <p className="area-data mb-0">
                                                                {dietObj.mode.join('&')}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                    )}
                                                </div>
                                                <div className="email-st">
                                                    {!lvisible ? (
                                                        <div>
                                                            <p className="address-heading gap-2 d-flex align-items-center">
                                                                Fees
                                                            </p>
                                                            <p className="area-data mb-0">
                                                                ₹{dietObj.fees}
                                                            </p>
                                                        </div>
                                                    ) : (
                                                        <Skeleton width="5rem" className="mb-2"></Skeleton>
                                                    )}
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row">
                        <TabView>
                            <TabPanel header="Dietitian Details">
                                <DietitianInfo
                                    registereddietitian={dietObj.registereddietitian}
                                    languages={dietObj.languages}
                                    services={dietObj.services}
                                    about={dietObj.about}
                                />
                            </TabPanel>
                            <TabPanel header="Education Details">
                                <EducationalInfo
                                    degree={dietObj.degree}
                                    collage={dietObj.collage}
                                    yearofcompletion={dietObj.yearofcompletion}
                                    certification={dietObj.certification}
                                    membership={dietObj.membership}
                                    awardsandrecognitions={dietObj.awards}
                                    certificationimages={dietObj.certificationimages}
                                />
                            </TabPanel>
                            <TabPanel header="Clinic Details">
                                <ClinicInfo
                                    clinicname={dietObj.clinicname}
                                    phone={dietObj.phoneno}
                                    email={dietObj.email}
                                    address={dietObj.clinicaddress}
                                    locality={dietObj.locality}
                                    city={dietObj.city}
                                    state={dietObj.state}
                                    pincode={dietObj.pincode}
                                    latitude={dietObj.location.coordinates[1]}
                                    longitude={dietObj.location.coordinates[0]}
                                    clinicwebsite={dietObj.clinicwebsite}
                                    clinicinstagram={dietObj.clinicinstaurl}
                                    clinictwitter={dietObj.clinictwitterurl}
                                    clinicyoutube={dietObj.clinicyoutubeurl}
                                    clinicfacebook={dietObj.clinicfacebookurl}
                                />
                            </TabPanel>
                            <TabPanel header="Timings">
                                <DietitianTimings timings={dietObj.timings} />
                            </TabPanel>
                            <TabPanel header="Images">
                                <ClinicAndDietitianImages
                                    clinicimages={dietObj.clinicimages}
                                    dietitianimages={dietObj.dietitianimages}
                                />
                            </TabPanel>
                        </TabView>
                    </div>

                    <div className="approve-reject-dialog">
                        <Dialog

                            visible={visible}
                            style={{ width: "30vw" }}
                            onHide={() => {
                                setVisible(false);
                            }}
                            footer={footerContent}
                        >
                            <p className="status-giving">
                                Are you sure you want to {status === 0 ? "reject" : "approve"}{" "} {dietObj.dietitiantitle} {" "}
                                {dietObj.dietitianname} ?
                            </p>
                            <p>Reason:</p>
                            <div className="row">
                                <div className="flex resoninput flex-column gap-2">
                                    <InputTextarea
                                    className=''
                                        placeholder="Add Reason..."
                                        value={commentObj.comment}
                                        onChange={(e: any) =>
                                            setCommentObj({ ...commentObj, comment: e.target.value })
                                        }
                                    />
                                </div>
                            </div>
                        </Dialog>

                    </div>
                </section>

            </div>
        </main>
    );
}

export default DietitianDetails;
