import HttpInstance from "./http.api";
import Cookies from "js-cookie";
import jwt_decode from "jwt-decode";
type Login={
    email:string
}
export enum SocialEnum {
    microsoft,
    google,
    facebook,
    amazon,
}

type otpObj={
    otp:number
}
export type SocialEnumKeyType = keyof typeof SocialEnum;
export const AdminLogin = (LoginObj:Login)=>{

  const RespData=  HttpInstance.post("/admin/adminlogin",LoginObj);
    return RespData
}

// export const RefreshToken = ()=>{
//     HttpInstance.get("/admin/refreshtoken").then((data)=>{
//         Cookies.set("accessToken",data.data.accessToken)
//     })
// }

function getEmail(provider: SocialEnumKeyType, profile: any) {
    return SocialEnum[provider] === SocialEnum.google
        ? profile.email
        : SocialEnum[provider] === SocialEnum.microsoft
            ? profile.userPrincipalName
            : SocialEnum[provider] === SocialEnum.facebook
                ? profile.email
                : '';
}

function getFullName(provider: SocialEnumKeyType, profile: any) {
    return SocialEnum[provider] === SocialEnum.google
        ? { firstName: profile.family_name, lastName: profile.given_name }
        : SocialEnum[provider] === SocialEnum.microsoft
            ? { firstName: profile.givenName, lastName: profile.surname }
            : SocialEnum[provider] === SocialEnum.facebook
                ? { firstName: profile.first_name, lastName: profile.last_name }
                : { firstName: '', lastName: '' }

}


export const signIn = async (provider: SocialEnumKeyType, profile: any) => {
    const email = getEmail(provider, profile);
    console.log("email"+email)
    const fullName = getFullName(provider, profile);
    try {
        const respObj = await AdminLogin({email:email.toLowerCase()})
        console.log(respObj);
        return respObj.status
    } catch (err: any) {
        console.log('error socialSignIn', err);
    }
}

export const getToken:any = () => {
    return localStorage.getItem("accessToken")
}

export const setToken = async(token:any) => {
    await localStorage.setItem("accessToken", token)
}

export const isTokenExpired = () => {
    const decoded:any = jwt_decode(getToken())
    console.log(decoded);
    return decoded.iat > Date.now()
}


export const getRefreshedToken =async ()=>{
    const rt = localStorage.getItem("refreshToken")
    await HttpInstance.get("/admin/refreshtoken?refreshToken="+rt).then((data)=>{
        localStorage.setItem("accessToken",data.data.data)
    })
}

export const refreshToken = async () => {
    const newToken = await getRefreshedToken()
    await setToken(newToken)
}

export const GetAdeminById = ()=>{
    const RespData = HttpInstance.get("/admin/getadminbyid")
    return RespData
 }

 export const verifyOtp = async (otp:otpObj) =>  {
    console.log('coming')
    try {
        const respObj = await HttpInstance.post('admin/verifyadminotp', otp);
        console.log("respObj",respObj.data.data);
        await localStorage.setItem("accessToken",respObj.data.data.accessToken)
        localStorage.setItem("refreshToken",respObj.data.data.refreshToken)
        return respObj.status
    } catch (err: any) {
        console.log('error otpverify', err);
    }
};


export const logout = () => {
    localStorage.clear(); //for localStorage
    sessionStorage.clear(); //for sessionStorage
  };