import React, { useState } from "react";
import Navbar from "../../components/ui/navbar/sidenav.components";
import "./settings.page.css";
import { Menu } from "primereact/menu";
import ExploreTable from "../../components/settings/Explore/exploreTable.components";
import PopularCategoryTable from "../../components/settings/PopularCategories/popularcategoryTable.components";
import CuisinesTable from "../../components/settings/Cuisines/cuisinesTable.components";
import BannersTable from "../../components/settings/Banners/bannersTable.components";
export default function Settings() {
  const [activeItem, setActiveItem] = useState(0); // Initialize activeItem state

  const handleItemClick = (index: number) => {
    setActiveItem(index); // Set active item based on the clicked index
  };

  return (
    <>
      <main>
        <div>
          <Navbar />
          <section className="page-content">
            <div className="ym-content-table">
              <div className="page_title d-sm-flex">
                <div className="d-flex align-items-center FE-heading-nav">
                  <a href="/dashboard" className="d-flex">
                    <span className="home-span">Dashboard</span>
                  </a>
                  <i
                    className="pi pi-angle-right"
                    style={{ color: "rgb(194, 194, 194)", fontSize: "12px" }}
                  ></i>
                  <span className="event-span">Settings</span>
                </div>
              </div>
              <div className="row d-flex mt-7">
                <div className="col-3 md:col-3">
                  <div className="card nav flex-column settings_nav_links mb-auto">
                    <a
                      className={`settings-menu-item ${
                        activeItem === 0 ? "active" : ""
                      } d-flex gap-2 align-items-center`}
                      onClick={() => handleItemClick(0)}
                    >
                      <span>Explore</span>
                    </a>
                    <a
                      className={`settings-menu-item ${
                        activeItem === 1 ? "active" : ""
                      } d-flex gap-2 align-items-center`}
                      onClick={() => handleItemClick(1)}
                    >
                      <span>Popular Categories</span>
                    </a>
                    <a
                      className={`settings-menu-item ${
                        activeItem === 2 ? "active" : ""
                      } d-flex gap-2 align-items-center`}
                      onClick={() => handleItemClick(2)}
                    >
                      <span>Cuisines</span>
                    </a>
                    <a
                      className={`settings-menu-item ${
                        activeItem === 3 ? "active" : ""
                      } d-flex gap-2 align-items-center`}
                      onClick={() => handleItemClick(3)}
                    >
                      <span>Banners</span>
                    </a>
                    {/* <a
                      className={`settings-menu-item ${
                        activeItem === 4 ? "active" : ""
                      } d-flex gap-2 align-items-center`}
                      onClick={() => handleItemClick(4)}
                    >
                      <span>Agents</span>
                    </a> */}
                  </div>
                </div>
                <div className="col-9 md:col-9">
                  {activeItem === 0 && <ExploreTable />}
                  {activeItem === 1 && <PopularCategoryTable />}
                  {activeItem === 2 && <CuisinesTable />}
                  {activeItem === 3 && <BannersTable />}
                  {/* {activeItem === 4 && <div className="card">4</div>} */}
                </div>
              </div>
            </div>
          </section>
        </div>
      </main>
    </>
  );
}
