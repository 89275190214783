import React, { useEffect, useRef, useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import "./details.css";
import { Button } from "primereact/button";
import Restaurantdetails from "../../../components/restaurant-details/restaurant-details.components";
import Navbar from "../../../components/ui/navbar/sidenav.components";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";
import { InputTextarea } from "primereact/inputtextarea";
import { Dialog } from "primereact/dialog";
import { GetRestByID } from "../../../api/pendingTable.api";
import { useNavigate, useParams } from "react-router-dom";
import { PostComment } from "../../../api/adminComments.api";
import { ApproveRestaurant } from "../../../api/restaurant-details.api";
import { RejectRestaurant } from "../../../api/restaurant-details.api";
import { useLocation } from "react-router-dom";
import Show from "../../../components/ui/toast/toast.components";
import Loader from "../../../components/ui/loader/loader.components";
import { Toast } from "primereact/toast";
import { Skeleton } from "primereact/skeleton";
import { Image } from "primereact/image";
import { Tooltip } from "primereact/tooltip";
import Restaurantphotos from "../../../components/RestaurantPhotos/Restaurant.photos.component";
import Restaurantmenu from "../../../components/RestaurantMenu/Restaurant.menu.component";
import RestaurantTimings from "../../../components/RestaurantTimings/restaurantTimings.component";
import AditionalInfo from "../../../components/RestaurantAditionalInfo/aditionalInfo.component";
import { devsas } from "../../../config/constants"
import EditRestaurant from "../edit-restaurant/EditRestaurant";

const Details = () => {
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  console.log(splitLocation);
  const { id } = useParams();
  const navigate = useNavigate();
  const [status, setStatus] = useState<number>(0);
  const [commentObj, setCommentObj] = useState({
    agentID: "",
    restID: "",
    comment: "",
    approvalstatus: "",
  });
  const [restObj, setRestObj] = useState({
    _id: "",
    name: "",
    address1: "",
    address2: "",
    city: "",
    state: "",
    maplink: "",
    diettype: [],
    location: {
      coordinates: [0, 0],
    },
    latitude: "",
    longitude: "",
    country: "",
    pincode: "",
    landmark: "",
    website: "",
    email: "",
    phoneno: "",
    eaterytype: [],
    foodtype: [],
    preference: [],
    cuisine: [],
    ambience: "",
    serving: [],
    mealoftheday: [],
    serviceoptions: [],
    values: [],
    features: [],
    specialitems: [],
    sourcelink: "",
    timings: [],
    days: [],
    source: "",
    about: [],
    whyinyeldam: [],
    images: [],
    menuimages: [],
    thumbnails: [],
    zomatolink: "",
    swiggylink: "",
    dineoutlink: "",
    averagecost: "",
    approvalstatus: 0,
    addedby: "",
    agentname: "",
    sizeofestablishment: "",
    hygiene:0,
    health:0,
    taste:0,
    costeffective:0,
    valuesrating:0,
    hospitality:0
  });
  const [visible, setVisible] = useState(false);
  const [editVisible, setEditVisible] = useState(false);
  const [lvisible, setLvisible] = useState(false);
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const restStatus = async () => {
    // const respData =await RestStatus(restObj._id,!restObj.status);
    // console.log(respData);
    // toastShow(respData.data.message)
    setVisible(false);
    // refreshPage();
  };
  const toast = useRef<Toast>(null);
  const show = (message: any, severity: any, summary: any) => {
    if (toast.current) {
      toast.current.show({
        severity: severity,
        summary: summary,
        detail: message,
      });
    } else {
      console.log("toast issue");
    }
  };

  const loadLazyData = () => {
    //imitate delay of a backend call
    const loadLazyTimeout = setTimeout(async () => {
      const resData = await GetRestByID(id as string);
      console.log("data called",resData.data.data.cuisine);
      setRestObj(resData.data.data);
      console.log(status);
      setCommentObj({
        ...commentObj,
        restID: resData.data.data._id,
        agentID: resData.data.data.addedby,
      });
      setLvisible(false);
    }, 100);
  };
  useEffect(() => {
    setLvisible(true);
    loadLazyData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const items = restObj.images.map((item) => {
    const style = { padding: 5 };
    return (
      <img src={item} width="300px" height="250px" style={style} alt="..." />
    );
  });

  const responsive = {
    0: {
      items: 1,
    },
    568: {
      items: 2,
    },
    600: {
      items: 3,
    },
    1024: {
      items: 3,
      itemsFit: "contain",
    },
  };
  const onSubmit = async () => {
    if (status === 1) {
      const respData = await ApproveRestaurant(id as string);
      show(respData.data.message, "info", "Info");
    } else {
      const respData = await RejectRestaurant(id as string, {
        approvalstatus: 2,
        comment: commentObj.comment
      });
      console.log("API hitting for reject");
      show(respData.data.message, "info", "Info");
    }
    const { pathname } = location;
    const splitpath = pathname.split("/");
    console.log("pathname" + splitpath[0]);
    const pathname_s = "/" + splitpath[1] + "/" + splitpath[2];
    navigate(pathname_s);
    // navigate("/pending")
  };
  const footerContent = (
    <div>
      <Button
        label="Cancel"
        icon="pi pi-times"
        onClick={() => {
          setVisible(false);
        }}
        className="p-button-outlined p-button-rounded"
      />
      <Button
        label="Confirm"
        icon="pi pi-check"
        className="p-button-rounded p-button-success change-btn-login"
        onClick={() => {
          onSubmit();
          restStatus();
        }}
        autoFocus
      />
    </div>
  );
  const pendingButtons = () => {
    return (
      <div className="ml-auto ">
        <Button
          label="Reject"
          icon="pi pi-times"
          onClick={() => {
            setVisible(true);
            setStatus(0);
            setCommentObj({ ...commentObj, approvalstatus: "1" });
            window.close();
          }}
          className="p-button-outlined p-button-rounded p-button-danger"
        />
        <Button
          label="Approve"
          icon="pi pi-check"
          className="p-button-rounded p-button-success change-btn-login"
          onClick={() => {
            setVisible(true);
            setStatus(1);
            setCommentObj({ ...commentObj, approvalstatus: "1" });
          }}
          autoFocus
        />
      </div>
    );
  };
  const approvedButton: React.FC = () => {
    return (
      <div className="ml-auto ">
        <Button
          label="Reject"
          icon="pi pi-times"
          onClick={() => {
            setVisible(true);
            setStatus(0);
          }}
          className="p-button-outlined p-button-rounded p-button-danger"
        />
      </div>
    );
  };
  const deniedButton: React.FC = () => {
    return (
      <div className="ml-auto ">
        <Button
          label="Approve"
          icon="pi pi-check"
          className="p-button-rounded p-button-success change-btn-login"
          onClick={() => {
            setVisible(true);
            setStatus(1);
          }}
          autoFocus
        />
      </div>
    );
  };

  function titleCaseWord(word: string) {
    if (!word) return word;
    return word[0].toUpperCase() + word.substr(1).toLowerCase();
  }

  return (
    <main>
      {!lvisible ? '' : <Loader />}
      <div>
        <Navbar />
        <section className="page-content">
          <div className="ym-content-table mt-0">
            <div className="page_title d-flex">
              <div className="p-2 d-flex">
                &nbsp;
                <span
                  className="back_rest_btn d-flex gap-2 align-items-center"
                  onClick={() => {
                    const currentPath = window.location.pathname;
                    if (currentPath.includes("/pending")) {
                      navigate(`/restaurants/pending`);
                    } else if (currentPath.includes("/approved")) {
                      navigate(`/restaurants/approved`);
                    } else if (currentPath.includes("/rejected")) {
                      navigate(`/restaurants/rejected`);
                    }
                  }}
                >
                  <i className="pi pi-arrow-left"></i>
                  Restaurant Details
                </span>
              </div>

              {splitLocation[2] === "pending" ? (
                <div className="ml-auto ">

                  <Button
                    label="Edit"
                    icon="pi pi-user-edit"
                    onClick={() => {
                      setEditVisible(true);

                    }}
                    className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                  />

                  <Button
                    label="Reject"
                    icon="pi pi-times-circle"
                    onClick={() => {
                      setVisible(true);
                      setStatus(0);
                      setCommentObj({ ...commentObj, approvalstatus: "0" });
                    }}
                    className="p-button-outlined p-button-rounded p-button-danger reject_btn"
                  />
                  <Button
                    label="Approve"
                    icon="pi pi-check-circle"
                    className="p-button-rounded p-button-success change-btn-login"
                    onClick={() => {
                      setVisible(true);
                      setStatus(1);
                      setCommentObj({ ...commentObj, approvalstatus: "1" });
                    }}
                    autoFocus
                  />
                </div>
              ) : splitLocation[2] === "approved" ? (
                <div className="ml-auto ">
                     <Button
                    label="Edit"
                    icon="pi pi-user-edit"
                    onClick={() => {
                      setEditVisible(true);

                    }}
                    className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                  />

                  <Button
                    label="Reject"
                    icon="pi pi-times-circle"
                    onClick={() => {
                      setVisible(true);
                      setStatus(0);
                      setCommentObj({ ...commentObj, approvalstatus: "0" });
                    }}
                    className="p-button-outlined p-button-rounded p-button-danger reject_btn"
                  />
                </div>
              ) : (
                <div className="ml-auto ">
                     <Button
                    label="Edit"
                    icon="pi pi-user-edit"
                    onClick={() => {
                      setEditVisible(true);

                    }}
                    className="p-button-outlined p-button-rounded p-button-danger edit_btn"
                  />
                  <Button
                    label="Approve"
                    icon="pi pi-check-circle"
                    className="p-button-rounded p-button-success change-btn-login"
                    onClick={() => {
                      setVisible(true);
                      setStatus(1);
                      setCommentObj({ ...commentObj, approvalstatus: "1" });
                    }}
                    autoFocus
                  />
                </div>
              )}
            </div>
            <hr/>

            <div className="ym-content-table mb-3 container">
              <div className="gap-3 d-flex col-lg-12">
                <div
                  className="row mb-1 col-lg-3 view-gallery-cols"
                  style={{ height: "180px" }}
                >
                  <div className=" pt-0 pl-0">
                    {/* @ts-ignore       */}
                    <Image src={restObj.thumbnails[0] + "?" + devsas} alt="Image" preview />
                  </div>
                </div>

                <div className="col-lg-9">
                  <div className="col d-flex align-items-left justify-content-center flex-column ml-1 gap-4 rest-details">
                    <div className="d-flex gap-3" >
                      <div className="page-title col-lg-6">
                        {!lvisible ? (
                          <h1 className="ym-portal-heading">{restObj.name}</h1>
                        ) : (
                          <Skeleton width="10rem" className="mb-2"></Skeleton>
                        )}
                      </div>
                      <div className="agent-disp col-lg-6">
                        {!lvisible ? (
                          <div className="d-flex agent-added justify-content-center align-items-center">
                            <label>Added By:</label>&nbsp;
                            <p className="mb-0">{restObj.agentname}</p>
                          </div>
                        ) : (
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                        )}
                      </div>
                    </div>
                    <div className="address-latlong gap-3 d-flex">
                      <div className="address-div col-lg-6">
                        {!lvisible ? (
                          <div>
                            <p className="address-heading gap-2 d-flex align-items-center">
                              <i
                                className="pi pi-map-marker"
                                style={{ fontSize: "12px" }}
                              ></i>
                              Address
                            </p>
                            <p className="area-data mb-0 ml-3">
                              {restObj.address1 +
                                "," +
                                restObj.city +
                                "," +
                                " " +
                                restObj.state +
                                "-" +
                                restObj.pincode +
                                "."}
                            </p>
                          </div>
                        ) : (
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                        )}
                      </div>
                      <div className="lat-long-div">
                        {!lvisible ? (
                          <div>
                            <p className="address-heading gap-2 d-flex align-items-center">
                              <i
                                className="pi pi-map"
                                style={{ fontSize: "12px" }}
                              ></i>
                              Latitude & Longitude
                            </p>
                            <p className="area-data mb-0 ml-4">
                              {restObj.location.coordinates[1] +
                                " & " +
                                restObj.location.coordinates[0]}
                            </p>
                          </div>
                        ) : (
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                        )}
                      </div>
                    </div>
                    <div className="phone-email-web d-flex gap-3">
                      <div className="phone-email d-flex col-lg-6">
                        <div className="phone-num col-lg-6">
                          {!lvisible ? (
                            <div>
                              <p className="address-heading gap-2 d-flex align-items-center">
                                <i
                                  className="pi pi-phone"
                                  style={{ fontSize: "12px" }}
                                ></i>
                                Phone
                              </p>
                              <p className="area-data mb-0 ">
                                <a href={`tel:${restObj.phoneno}`}>
                                  {restObj.phoneno}
                                </a>
                              </p>

                            </div>
                          ) : (
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                          )}
                        </div>
                        <div className="email-st">
                          {!lvisible ? (
                            <div>
                              <p className="address-heading gap-2 d-flex align-items-center">
                                <i
                                  className="pi pi-envelope"
                                  style={{ fontSize: "12px" }}
                                ></i>
                                Email
                              </p>
                              <p className="area-data mb-0">
                                <a href={`mailto:${restObj.email}`} className="area-data mb-0">
                                  {restObj.email}
                                </a>
                              </p>
                            </div>
                          ) : (
                            <Skeleton width="5rem" className="mb-2"></Skeleton>
                          )}
                        </div>
                      </div>
                      <div className="website">
                        {!lvisible ? (
                          <div>
                            <p className="address-heading gap-2 d-flex align-items-center">
                              <i
                                className="pi pi-globe"
                                style={{ fontSize: "12px" }}
                              ></i>
                              Website
                            </p>
                            <p className="area-data mb-0 ml-4">
                              {" "}
                              {restObj.website}
                            </p>
                          </div>
                        ) : (
                          <Skeleton width="5rem" className="mb-2"></Skeleton>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <TabView
                activeIndex={activeIndex}
                onTabChange={(e) => setActiveIndex(e.index)}
              >
                {/* @ts-ignore */}

                <TabPanel header="Dining Details">
                  <Restaurantdetails
                    cuisine={restObj.cuisine}
                    specialitems={restObj.specialitems}
                    preference={restObj.preference}
                    foodtype={restObj.foodtype}
                    eaterytype={restObj.eaterytype}
                    ambience={restObj.ambience}
                    values={restObj.values}
                    serving={restObj.serving}
                    serviceoptions={restObj.serviceoptions}
                    phoneno={restObj.phoneno}
                    address={restObj.address1 + "," + restObj.address2}
                    sourcelink={restObj.sourcelink}
                    images={restObj.images}
                    description={restObj.about}
                    agentname={restObj.agentname}
                    whyyeldam={restObj.whyinyeldam}
                    zomatolink={restObj.zomatolink}
                    swiggylink={restObj.swiggylink}
                    dineoutlink={restObj.dineoutlink}
                    maplink={restObj.maplink}
                    email={restObj.email}
                    website={restObj.website}
                    city={restObj.city}
                    state={restObj.state}
                    pincode={restObj.pincode}
                    mealoftheday={restObj.mealoftheday}
                    averagecost={restObj.averagecost}
                    features={restObj.features}
                    diettype={restObj.diettype}
                    sizeofestablishment={restObj.sizeofestablishment}
                  />
                </TabPanel>
                {/* @ts-ignore       */}
                <TabPanel header="Timings">
                  <RestaurantTimings timing={restObj.timings} editable={false}/>
                </TabPanel>
                <TabPanel header="Restaurant Images">
                  <Restaurantphotos images={restObj.images} />
                </TabPanel>
                {/* @ts-ignore       */}
                <TabPanel header="Menu">
                  <Restaurantmenu
                    menuimages={restObj.menuimages}
                  />
                </TabPanel>
                <TabPanel header="Additional Info">
                  <AditionalInfo
                    description={restObj.about}
                    whyyeldam={restObj.whyinyeldam}
                    features={restObj.features}
                    hygiene={restObj.hygiene}
                    health={restObj.health}
                    taste={restObj.taste}
                    costeffective={restObj.costeffective}
                    valuesrating={restObj.valuesrating}
                    hospitality={restObj.hospitality}
                  />
                </TabPanel>
              </TabView>
            </div>
            <Dialog
              header="Confirm Status Change"
              visible={visible}
              style={{ width: "50vw" }}
              onHide={() => {
                setVisible(false);
              }}
              footer={footerContent}
            >
              <p>
                Are you sure you want to {status === 0 ? "reject" : "approve"}{" "}
                {restObj.name} ?
              </p>
              <p>Comments:</p>
              <div className="row">
                <div className="flex flex-column gap-2">
                  <InputTextarea
                    placeholder="Add Comments..."
                    value={commentObj.comment}
                    onChange={(e: any) =>
                      setCommentObj({ ...commentObj, comment: e.target.value })
                    }
                  />
                </div>
              </div>
            </Dialog>
            <Dialog
              header="Edit Restaurant Details"
              visible={editVisible}
              style={{ width: "45vw", height: "100vh" }}
              onHide={() => {
                setEditVisible(false);
              }}

            >
              <EditRestaurant
                restdataObj={restObj}
                setEditVisible={setEditVisible}
              />
            </Dialog>
          </div>
        </section>
      </div>
    </main>
  );
};

export default Details;
