import React, { useEffect, useState } from 'react';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { GetHealthtips } from '../../api/healthtips.api';
import { yeldamwebimagessas } from '../../config/constants';
import "./Healthtips-Table.component.css";

type healthtipsprops = {
    actionBodyTemplate: any,

}


const HealthtipsTable:React.FC<healthtipsprops> = ({actionBodyTemplate}) => {
    const imageBodyTemplate = (rowData: any) => {
        return <img src={rowData.tipimages+"?"+yeldamwebimagessas} alt={rowData.imageurl} className="w-6rem shadow-2 border-round" />;
    
      };
    const [data, setData] = useState<any[]>([]);
    useEffect(() => {
        // Function to fetch data from the API
        const fetchData = async () => {
            try {
                const requestData = {
                    rows: 10,
                    page: 0,
                    sortOrder: -1,
                    sortField: "createdAt",
                    filters: {
                        tipcategory: { value: "" },
                        tipname: { value: "" }
                    }
                };
                const healthTipsData = await GetHealthtips(requestData); 
                console.log("Healthtips:",healthTipsData.data.healthtips);// Call the GetHealthtips function with request data
                setData(healthTipsData.data.healthtips); // Update the state with fetched data
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

        fetchData(); // Call the fetchData function when the component mounts
    }, []);

    // const staticData = [
    //     { serialnumber: 1, title: 'Eat Plenty of Vegetables', Category: 'Depression and Anxiety',  status: 'Active' },
    //     { serialnumber: 2, title: 'Include Whole Grains in Meal', Category: 'Depression and Anxiety',  status: 'Inactive' },
    //     { serialnumber: 3, title: 'Include Whole Grains in Meal', Category: 'Depression and Anxiety',  status: 'Inactive' },
    //     { serialnumber: 4, title: 'Include Whole Grains in Meal', Category: 'Depression and Anxiety',  status: 'Inactive' },
    //     { serialnumber: 5, title: 'Include Whole Grains in Meal', Category: 'Depression and Anxiety',  status: 'Inactive' },
    //     { serialnumber: 6, title: 'Include Whole Grains in Meal', Category: 'Depression and Anxiety',  status: 'Inactive' },
    //     { serialnumber: 7, title: 'Include Whole Grains in Meal', Category: 'Obesity',  status: 'Inactive' },
        
    //   ];

  return (
    <div>
      <DataTable className='healthtips-table' value={data}>
        
      <Column field='serialnumber' header='S.NO' body={(rowData, columnMeta) => columnMeta.rowIndex + 1} />
            <Column field='tipname' header='Title'/>
            <Column field='tipcategory' header='Category'/>
            <Column  field='tipimages' body={imageBodyTemplate} header='Image'/>
            <Column field='tipstatus' sortable header='Status' />
            <Column body={(rowData, columnMeta)=>actionBodyTemplate(rowData)} field='action' header='Action'  />

        </DataTable>
      
    </div>
  );
}

export default HealthtipsTable;
